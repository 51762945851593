import React from 'react'

import "./ServicesHero.css"
import TileShimmer from '../shimmer/TileShimmer'

function ServicesHero({data}) {

    // const data = 
    // window.addEventListener("load", function () {
    //     const bars = document.querySelectorAll(".progress");
    //     bars.forEach((bar) => {
    //         bar.classList.add("progressAnim");
    //     })
    // })

    return (
        <div className={`container-fluid pt-3 px-3 px-md-5 content-element ${data.progressBars[0] ? 'loaded' : ''} `}>
            <div className="row row-gap-3 gx-0">
                <div className={`imgSection col-lg-6 g-0 content-element ${data.progressBars[0]  ? 'loaded' : ''} `}>
                    <div className="row h-100">
                        <div className="col-8 g-0 pe-2">
                            <div className="imgWrapper h-100 w-100  Imageeffects">
                                <img src="https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="laptop" className='w-100 h-100 transition' />
                            </div>
                        </div>
                        <div className="col-4 g-0 xCenter justify-content-between ">
                            <div className="imgWrapper w-100 pb-2 Imageeffects" style={{ height: "40%" }}>
                                <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="website" width={"100%"} height={"100%"} className='transition' />
                            </div>
                            <div className="imgWrapper w-100 Imageeffects" style={{ height: "60%" }}>
                                <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="website" height={"100%"} className='transition' />
                            </div>
                        </div>
                    </div>
                    {/* <div className="row g-0 pb-3" style={{ height: "50%" }}>
                        <div className="col-8 pe-3">
                            <div className="imgWrapper">
                                <img src="https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="laptop" height={"100%"} />
                            </div>
                        </div>
                        <div className="col-4 xCenter justify-content-between">
                            <div className="imgWrapper pb-3" style={{ height: "40%" }}>
                                <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="website" width={"100%"} height={"100%"} />
                            </div>
                            <div className="imgWrapper" style={{ height: "60%" }}>
                                <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="website" height={"100%"} />
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row g-0 pb-3" style={{ height: "50%" }}>
                        <div className="col-4 xCenter justify-content-between">
                            <div className="imgWrapper pb-3" style={{ height: "40%" }}>
                                <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="website" width={"100%"} height={"100%"} />
                            </div>
                            <div className="imgWrapper" style={{ height: "60%" }}>
                                <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="website" height={"100%"} />
                            </div>
                        </div>
                        <div className="col-8 ps-3">
                            <div className="imgWrapper">
                                <img src="https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8d2Vic2l0ZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="laptop" height={"100%"} />
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className={`col-lg-6 px-3 ps-lg-5 content-element ${data.progressBars[0] ? 'loaded' : ''} `}>
                    <div className='xCenter gap-3'>
                        <h5 className='m-0 yCenter gap-2 gradientText text-uppercase fw-bold'><img src="img/line.svg" alt="" /><span>{data.subTitle}</span></h5>
                        <h2 className='h1 m-0 titleFont bigHeading' dangerouslySetInnerHTML={{__html: data.title}}/>
                        <p className='m-0 fs-5 text-secondary fw-semibold'>{data.text}</p>
                        {/* Bar Section ========================= */}
                        <div className="bars xCenter gap-3">
                            {/* Bars ===================== */}
                            {data.progressBars.map((bar,index)=>{
                                return (
                                    (bar) ?
                                    <div key={"progressbar"+index} className="bar bar1 xCenter gap-2">
                                        <h5 className='h5 m-0 textDark text-uppercase fw-bold' style={{ color: "var(--textDark)" }}>{bar.title}</h5>
                                        <div className="barWrapper rounded-1 w-100 lighterBg">
                                            <div className='progress gradientBg h-100 rounded-0 rounded-start-1' style={{width: bar.value}}></div>
                                        </div>
                                    </div>
                                    :
                                    <TileShimmer key={"progressbar"+index}/>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesHero
