import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquare } from "@fortawesome/free-solid-svg-icons"
import "./Process.css"
import TileShimmer from "../shimmer/TileShimmer"

function Process({data}) {
  return (
    <div className="container process py-3">
      <div className="row">
        <div className="col-12 allCenter">
          <h5 className="gradientText yCenter gap-2">
            <FontAwesomeIcon icon={faSquare} className="square" />
            {data.subTitle}
            <FontAwesomeIcon icon={faSquare} className="square" />
          </h5>
          <h2 className="h1 titleFont" dangerouslySetInnerHTML={{ __html: data.title }} />
        </div>
      </div>
      <div className="row mt-3">
        {data.processCards.map((processCard, index) => {
          return (
            (processCard === null) ?
            <div key={"processCard"+index} className="col-sm-6 col-md-3 mb-4">
              <TileShimmer/>
            </div>
            :
            <div key={"processCard"+index} className="col-sm-6 col-md-3 mb-4">
              <div className="step transition text-center p-3 bgSecondary h-100">
                <div className="label allCenter transition fw-bold titleFont">{index + 1}</div>
                <img src={processCard.img} alt="icon" className="w-10 mb-4 processImage" />
                <p className="fw-bold h5 mb-2" >{processCard.title}</p>
                <p className="text-secondary fw-semibold">{processCard.text}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Process