import React from "react";
import "./Hero.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import RecentBlogs from "../recentBlogs/RecentBlogs";
import TileShimmer from "../shimmer/TileShimmer";
import { Link } from "react-router-dom";

function Hero({ data }) {
  function toggleRecent() {
    var recent = document.querySelector(".recentPosts");
    recent.classList.toggle("notVisible");
  }

  return (
    <div
      className={`container-fluid hero gx-0 pb-5 content-element ${
        data ? "loaded" : ""
      } `}
    >
      {data == null ? (
        <>
          <TileShimmer />
          <TileShimmer />
          <TileShimmer />
        </>
      ) : (
        <div className="d-flex position-relative">
          <div className="leftNavbar p-0 allCenter" data-aos="fade-right">
            <div
              className="lines xCenter gap-2 pb-3 cursorPointer cursorFollower"
              style={{ width: "40px", margin: "4rem 0" }}
              onClick={toggleRecent}
            >
              {/* <Cursor /> */}
              <span className="bg-black"></span>
              <span className="bg-black w-75"></span>
              <span className="bg-black w-80"></span>
            </div>
            <div className="socialMedia allCenter gap-4 py-3">
              <Link
                to="https://www.instagram.com/aashish26jain/"
                target="_blank"
                className="allCenter rounded-circle p-1 position-relative socialImg"
              >
                <img
                  src="https://admin.dizzibooster.com/public/images/sknrxtbwpmy650c1d5fb9deb.png"
                  alt="aashish26jain"
                  className="w-100 h-100 profileImg"
                />
                <span className="icon">
                  <img
                    src="https://admin.dizzibooster.com/public/images/ypxebcnahov650c1d7be429b.jpg"
                    alt="instagram"
                    className="w-100 h-100"
                  />
                </span>
              </Link>
              <Link
                to="https://www.instagram.com/bussinessman_thoughts/"
                target="_blank"
                className="allCenter rounded-circle p-1 position-relative socialImg"
              >
                <img
                  src="https://admin.dizzibooster.com/public/images/sknrxtbwpmy650c1d5fb9deb.png"
                  alt="learningsessions"
                  className="w-100 h-100 profileImg"
                />
                <span className="icon">
                  <img
                    src="https://admin.dizzibooster.com/public/images/alywipcxgrt65151e47c6d76.jpg"
                    alt="instagram"
                    className="w-100 h-100"
                  />
                </span>
              </Link>
              <Link
                to="https://www.instagram.com/dizzibooster/"
                target="_blank"
                className="allCenter rounded-circle p-1 position-relative socialImg"
              >
                <img
                  src="https://admin.dizzibooster.com/public/images/sknrxtbwpmy650c1d5fb9deb.png"
                  alt="dizzibooster"
                  className="w-100 h-100 profileImg"
                />
                <span className="icon">
                  <img
                    src="https://admin.dizzibooster.com/public/images/vutkxyjwqns650c1d3c51d82.jpg"
                    alt="instagram"
                    className="w-100 h-100"
                  />
                </span>
              </Link>
              <Link
                to="https://www.instagram.com/learningsessions.in/"
                target="_blank"
                className="allCenter rounded-circle p-1 position-relative socialImg"
              >
                <img
                  src="https://admin.dizzibooster.com/public/images/sknrxtbwpmy650c1d5fb9deb.png"
                  alt="learningsessions"
                  className="w-100 h-100 profileImg"
                />
                <span className="icon">
                  <img
                    src="https://admin.dizzibooster.com/public/images/ksonvhdbwqr650c1d94e9e22.jpg"
                    alt="instagram"
                    className="w-100 h-100"
                  />
                </span>
              </Link>
            </div>
          </div>
          <div className="heroSection col-md-11  gx-0 p-0">
            <div className="hero pt-5">
              <div className="mask"></div>
              <div className="blackShade">
                <img
                  className="w-100 h-100"
                  src={data.background}
                  alt="hero"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="row gx-0 py-5 my-5 text-center">
                <div className="col-12 gx-0 p-0">
                  <h2
                    className="m-0 h1 titleFont text-white bigHeading fw-medium mainHeroTitle"
                    dangerouslySetInnerHTML={{ __html: data.title }}
                  />
                </div>
              </div>
              <div className="row gx-0 p-0 pt-2 mt-3">
                <div className="col-lg-6 p-0 px-4 px-md-5 content">
                  <p className="gradientText yCenter gap-2 fw-bold">
                    <FontAwesomeIcon icon={faSquare} className="square" />
                    {data.info}
                    <FontAwesomeIcon icon={faSquare} className="square" />
                  </p>
                  <h2
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html: data.subTitle }}
                  />
                  <p className="z-3 m-0 my-2 ls-base fw-semibold text-secondary">
                    {data.text}
                  </p>
                </div>
              </div>
              <div
                className="row gx-0 pt-sm-5 laptopContainer "
                style={{ marginTop: "9rem" }}
              >
                <div className="col-lg-6">&nbsp;</div>
                <div className="col-lg-6 p-0  allCenter">
                  <div className="laptop  hovereffect">
                    <img
                      src={data.laptop}
                      alt="laptop"
                      className="img-fluid transition bottom-0"
                      height={"100%"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="recentPosts position-fixed border-start transition notVisible">
            <RecentBlogs />
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero;
