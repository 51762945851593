import axios from "axios";
const api = "https://admin.dizzibooster.com/";
// const api = "http://localhost:8080/Dizzibooster/api/";


const fetchPage = async (pageKey) => {
    try {
        const response = await axios.post(`${api}pages/getPage`, { page: pageKey }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data.page;

    } catch (error) {
        console.error("Error fetchiṆng data:", error);
    }
}
const fetchHead = async (pageKey) => {
    try {
        const response = await axios.post(`${api}pages/getPage`, { page: pageKey }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data.page;
    } catch (error) {
        console.error("Error fetchiṆng data:", error);
    }
}
const fetchPostContent = async (slug) => {
    try {
        const response = await axios.post(`${api}pages/getPostContent`, { slug }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetchiṆng data:", error);
    }
}
const fetchPosts = async (count) => {
    try {
        const response = await axios.post(`${api}pages/getPosts`, { count }, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data.posts;
    } catch (error) {
        console.error("Error fetchiṆng data:", error);
    }
}
const fetchCategories = async () => {
    try {
        const response = await axios.post(`${api}pages/getCategories`, {}, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data.categories;
    } catch (error) {
        console.error("Error fetchiṆng data:", error);
    }
}
const submitForm = async (form) => {
    try {
        const response = await axios.post(`${api}pages/submitForm`, form, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        console.log(response);
        return true
    } catch (error) {
        console.error("Error Form Submition:", error);
    }
}

const togglePopup = (popup, animation) => {
    // console.log(popup);
    if(popup){
      popup.classList.toggle("d-none");
      if(animation) {
          setTimeout(() => {
              popup.querySelector('.mainPopup').classList.toggle("slideAnimation");
        }, 1)               
      }
    }
}

const share = (platform, url, title=null) => {
    const facebook = 'https://www.facebook.com/sharer/sharer.php?u=';
    const instagram = 'instagram://share?text=';
    const telegram = 'https://t.me/share/url?url=';
    const twitter = 'https://twitter.com/intent/tweet?text=';
    const whatsapp = 'https://api.whatsapp.com/send?text=';
    const snapchat = 'snapchat://share?url=';
    
    switch(platform) {
        case "facebook":
            const facebookShareUrl = facebook + encodeURIComponent(url);
            window.open(facebookShareUrl, '_blank');
            break;
        case "instagram":
            const instagramShareUrl = instagram + encodeURIComponent(url);
            window.location.href = instagramShareUrl;
            break;
        case "telegram":
            const telegramShareUrl = telegram + encodeURIComponent(url);
            window.open(telegramShareUrl, '_blank');
            break;
        case "twitter":
            const tweetText = title !== null ? `Check out this blog: ${title}` : 'Check out this awesome blog!';
            const twitterShareUrl = twitter + encodeURIComponent(tweetText) + '&url=' + encodeURIComponent(url);
            window.open(twitterShareUrl, '_blank')
            break;
        case "whatsapp":
            const whatsappShareUrl = whatsapp + encodeURIComponent(url);
            window.open(whatsappShareUrl, '_blank');
            break;
        case "snapchat":
            const snapchatShareUrl = snapchat + encodeURIComponent(url); // You can also include a pre-filled message here
            window.location.href = snapchatShareUrl;
            break;
        default:
            window.open(whatsappShareUrl, '_blank');
    }
}

export {fetchPage,fetchHead,fetchPostContent,fetchPosts,fetchCategories,submitForm,togglePopup, share};