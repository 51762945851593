import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./List.css"

function List({list}) {

  return (
    <div className='list xCenter gap-3'>
      <h2 className='m-0 '>{list.heading}</h2>
      <p className='m-0 text-secondary fs-5'>{list.description}</p>
      <ul className='list text-secondary fs-5 fw-semibold m-0 ps-0' style={{listStyle: "none"}}>
        {list.list.map((listItem,index) => {
            return (
              <li key={"postList"+index}>
                  <FontAwesomeIcon icon={faCircleCheck} className='icon gradientText pe-2' style={{width: "16px"}}/>
                  {listItem}
              </li>
            )
        })}
      </ul>
    </div>
  )
}

export default List
