import React, { useEffect, useState } from 'react'
import HeroBasic from '../components/heroBasic/HeroBasic'
import PostContent from '../components/post/PostContent'
import Category from '../components/category/Category'
import Recent from '../components/recent/Recent'
import Tags from '../components/tags/Tags'
import ContactForm from '../components/contactForm/ContactForm'
import SubscribeBar from '../components/subscribBar/SubscribeBar'
import { Navigate, useLocation } from 'react-router-dom';
import { fetchPage, fetchPostContent } from '../functions'
import HeadTag from '../components/headTag/HeadTag'

function Post() {
    const { pathname } = useLocation();

    const changeSlug = (newSlug) => {
        setPostSlug(newSlug)
    };

    const tempData = {
        pagination :{
            prevIcon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/arrow-prev-one.svg",
            prevBtn: "Previous Post",
            nextBtn: "Next Post",
            nextIcon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/arrow-next-one.svg"
        },
        hero : {
            img: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/banner/banner-five/banner5-2.jpg",
            title: "Blog Details",
            main: "Home",
            currentPg: "Page Details",
        }
    }
    const [postSlug,setPostSlug] = useState(pathname.split('/').pop());
    const [postData,setPostData] = useState(tempData);
    const [postContent,setPostContent] = useState(null);
    const [postTags,setPostTags] = useState([]);
    const [head,setHead] = useState(null);
    const [postFound, setPostFound] = useState(true);
    useEffect(() => {
        const getData =async ()=>{
            setPostContent(null);
            const postContentData = await fetchPostContent(postSlug);
            if(postContentData.status !== 404) {
                setPostContent(postContentData.postContent);
                setHead(JSON.parse(postContentData.meta));
                const tags = postContentData.tags.split(',');
                setPostTags(tags);
                setPostFound(true);
            } else {
                setPostFound(false);
            }
            const postData = await fetchPage("dizzi_single_post");
            setPostData(postData);
        }
        getData();
    }, [postSlug])

    return (
        <div className='container-fluid gx-0'>
            <HeadTag data={head}/>
            <HeroBasic data={postData.hero}/>
            <div className='container'>
                <div className="row blog-shortComponents py-2 py-md-4">
                    <div className="col-lg-8 pe-lg-5 py-2 py-md-4">
                        <div className="post">
                        {/* Post here */}
                            {postFound ? <PostContent key={"postContentComponent"+postSlug} data={postContent}/> :  <Navigate to="/post/post-not-found" replace={true} />}
                        </div>
                        <div className="yCenter justify-content-between py-2 py-md-4">
                            <span className='btn icon yCenter justify-content-start ps-0 gap-2'>
                                <img src={postData.pagination.prevIcon} alt="Previous-icon" width={"27%"}/>
                                {postData.pagination.prevBtn}
                            </span>
                            <span className='btn icon yCenter justify-content-end pe-0 gap-2'>
                                {postData.pagination.nextBtn}
                                <img src={postData.pagination.nextIcon} alt="Next-icon" width={"32%"}/>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-4 py-2 py-md-4">
                        <div className="row row-gap-5">
                            {/* <div className="col-12 search p-0"><Search/></div> */}
                            <div className="col-md-6 col-lg-12 px-0 pe-md-3"><div className='p-3 p-md-4 category border'><Category/></div></div>
                            <div className="col-md-6 col-lg-12 p-3 p-md-4 border recent blogpageRecent"><Recent changeSlug={changeSlug}/></div>
                            <div className="col-md-6 col-lg-12 px-0 pe-md-3"><Tags tags={postTags}/></div>
                            <div className="col-md-6 col-lg-12 p-3 p-md-4 contact" style={{backgroundColor: "#fff9ee"}}><ContactForm/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-3 subscribe"><SubscribeBar/></div>
        </div>
    )
}

export default Post
