import { faCirclePlay, faScissors } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./Assaurance.css"
import TileShimmer from '../shimmer/TileShimmer'
import { Link } from 'react-router-dom'
import Video from '../popups/video/Video'

function Assurance({data}) {

    // video toggle 
    function togglePopup(popup) {
        popup.classList.toggle("d-none");
    }

    return ( 
        <div className='container-fluid assuarance py-5 px-5 px-md-2 position-relative'  style={{overflowX: "hidden"}}>
            <div className={`row pt-0 pt-md-5 row-gap-5 content-element ${data ? 'loaded' : ''} `}>
                {(data.title) ? 
                    (
                        <>
                            <div className="col-md-7 ps-3 px-md-5 xCenter gap-3 transition">
                                <h5 className='m-0 fs-6 yCenter gap-2 gradientText text-uppercase fw-bold'>{data.subTitle}</h5>
                                <h2 className='h1 m-0 titleFont'>{data.title}</h2>
                                <div className='yCenter gap-4'>
                                    <FontAwesomeIcon icon={faScissors} className='text-gradient' style={{ color: "var(--red)" }} />
                                    <p className='m-0 fs-4'>{data.titleText}</p>
                                </div>
                                <p className='m-0 fw-semibold text-secondary ps-5'>{data.text}</p>
                                <div className="signature ps-5 transition hovereffect">
                                    <img src={data.signature} alt="signature" className='transition' width={"50%"} />
                                </div>
                            </div>
                            <div className="col-md-5 pt-5 d-none d-md-block  pt-md-0 position-relative">
                                <div className="videoWrapper rounded-pill transition position-relative">
                                    <div className="imgWrapper transition position-absolute top-0 start-0 w-100 h-100" style={{ backgroundImage: `url(${data.videoBackground})` }}>
                                    </div>
                                </div>
                                <button type='button' className='position-absolute p-3 end-0 gradientBg border-0' style={{ width: "200px", top: "75%" }}>
                                    <Link to={data.buttonText.path} className='text-start text-decoration-none'>
                                        <p className='m-0 text-uppercase fw-semibold' style={{color: "var(--secondColor)"}}>{data.buttonText.top}</p>
                                        <p className='m-0 text-white fw-semibold'>{data.buttonText.bottom}</p>
                                    </Link>
                                </button>
                                <FontAwesomeIcon icon={faCirclePlay} className='icon position-absolute start-50 fs-1 text-white cursorPointer' style={{top: "30%"}} onClick={() => togglePopup(document.getElementById("videoPopupCover"), false)}/>
                            </div>
                            <Video videoUrl={data.videoUrl} />
                        </>
                    ) 
                    :
                    <TileShimmer/>
                }
            </div>
        </div>
    )
}

export default Assurance
