import React from 'react'
import "./ServiceShort.css"
import TileShimmer from '../shimmer/TileShimmer'

function ServicesShort({data}) {


  return (
    <div className='servicesShort pt-3 pt-md-4 xCenter justify-content-between gap-2' style={{backgroundColor: "#fff9ee"}}>
      <h2 className='px-3 px-md-4 py-0  m-0 fs-4 fw-bold'>{data.heading}:</h2>
      <div className='xCenter w-100' style={{gap: "2px"}}>
        {data.services.map((service,index) => {
          if(service == null){
            return <TileShimmer key={"tileShortServices"+index}/>
          }
          return(
            <div key={"serviceBanner"+index} className="service px-3 px-md-4 py-2 d-flex align-items-start justify-content-center gap-2 bgSecondary">
              <div className="content xCenter justify-content-between gap-1" style={{width: "80%"}}>
                <h2 className='fs-5 fw-semibold m-0'>{service.serviceName}</h2>
                <p className='m-0 text-secondary' style={{fontSize: "0.8rem"}}>{service.text}</p>
              </div>
              <div className="imgWrapper" style={{width: "20%"}}>
                <img src={service.img} alt="service" className='w-100 h-100'/>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ServicesShort
