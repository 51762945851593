import React from 'react'
import "./PostShimmer.css"
function PostShimmer() {
  return (
    <div className="shimmerCard">
        <div className="shimmer"></div>
        <div className="shimmerBG media"></div>
        <div className="p-32">
          <div className="shimmerBG title-line"></div>
          <div className="shimmerBG title-line end"></div>

          <div className="shimmerBG content-line m-t-24"></div>
          <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line"></div>
          <div className="shimmerBG content-line end"></div>
        </div>
    </div>
  )
}

export default PostShimmer