import React from 'react'
import "./AboutService.css"
import TileShimmer from '../shimmer/TileShimmer'

function AboutService({data}) {
  if(data !== null){
    return (
      <div className='aboutServiceContainer xCenter gap-5'>
        <div className="textContent mt-2 xCenter gap-3">
          <h3 className="m-0 title fw700">{data.title}</h3>
          <p className="m-0 text-secondary" style={{fontSize: "18px"}} dangerouslySetInnerHTML={{__html: data.text}} />
          <div className="points row mt-2 transition">
            <div className="col-md-5">
              <ul className="pointsText p-0 xCenter gap-2">
                {data.points.map((point, index) => {
                  return(
                    <li key={"point"+index} className='text-secondary'>{point}</li>
                  )
                })}
              </ul>
            </div>
            <div className="col-md-7 serviceListIm transition">
              <div className="imgWrapper transition hovereffect"><img src={data.img} alt="service-list" className='w-100 h-100 transition' /></div>
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return <TileShimmer/>
  }
}

export default AboutService
