import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./Quote.css"

function Quote({quote}) {


  return (
    <div className='quoteContent position-relative py-5 px-4 px-md-5'>
      <FontAwesomeIcon icon={faQuoteLeft} className='quoteIcon gradientBg rounded-circle p-2 fs-2 text-white position-absolute'/>
      <div className="text position-relative">
        <p className='m-0 text-secondary fs-4 fw-medium'>
          {quote.qouteText}
          <FontAwesomeIcon icon={faQuoteRight} className='lighterText ms-2 d-inline'/>
        <span className='m-0 h5 position-absolute top-100 bottom-0 end-0 fw-semibold text-black quoteAuther'>{quote.quoteAuthor}</span>
        </p>
      </div>
    </div>
  )
}

export default Quote
