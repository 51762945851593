import React, { useEffect, useState } from 'react'
import { fetchPage } from '../../functions';
import "./SubscribeBar.css"
import { Field, Form, Formik } from 'formik';

function SubscribeBar() {

    const tempData = {
        subscribeText: "If You Are Interseted,<br />Subscribe Our Newsletter!",
        placeholder: "Write Email...",
        btnText: "Subscribe",
    }

    const subscribe = () => {
        // Animation
        document.querySelector('.subscription').classList.add('done');
    };
    
    const [newsletterData,setNewsletterData] = useState(tempData);
    useEffect(() => {
        const getData =async ()=>{
            const data = await fetchPage('dizzi_newsletter');
            setNewsletterData(data);
        }
        getData()
        
    }, [])

    return (
        <div className='subsctibeBar px-3 px-sm-5 py-4 bgSecondary'>
            <div className="row row-gap-3">
                <div className="col-sm-6">
                    <div className='w-md-75 h-100 xCenter'>
                        <h5 className='m-0 fw-bold' dangerouslySetInnerHTML={{ __html: newsletterData.subscribeText }} />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className='h-100 w-100'>
                        {/* <input type="text" placeholder={newsletterData.placeholder} className='px-2 px-lg-3 py-2 h-100 w-100 bg-transparent border-0' />
                        <button className="gradientBg allCenter border-0 text-uppercase fw-bold text-white px-3 px-lg-4 h-100" style={{fontSize: "0.8rem"}}>
                            {newsletterData.btnText}
                        </button> */}
                        <Formik initialValues={{email: ""}} onSubmit={subscribe}>
                            <Form className="subscription">
                                <Field className="add-email" type="email" id="subscribeEmail" name="email" placeholder="subscribe@me.now" required/>
                                <button className="submit-email" type="submit">
                                    <span className="before-submit">Subscribe</span>
                                    <span className="after-submit">Thank you for subscribing!</span>
                                </button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeBar
