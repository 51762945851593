import React from 'react'
import "./ContactForm.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMessage, faPhoneVolume, faUser } from '@fortawesome/free-solid-svg-icons'
import { Formik, Field, Form } from 'formik';
import { submitForm, togglePopup } from '../../functions';

function ContactForm() {

    const data = {
        heading: "Feel Free To Connect",
    }

    return (
        <div className='contactForm xCenter gap-3'>
            <h2 className='m-0 fs-4 fw-bold'>{data.heading}:</h2>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    mobile: '',
                    message: '',
                }}
                onSubmit={async (values) => {
                    await submitForm(values);
                    // Thanku Popup Here function Here
                    togglePopup(document.getElementById("thankuPopupCover"), true); // 1. Popup 2. Animation - true/false
                }}
            >
                <Form className="inputs xCenter gap-4">
                    <div className="col-12">
                        <div className="name field yCenter gap-2">
                        <FontAwesomeIcon icon={faUser} className='icon'/>
                            <Field
                                id="name"
                                name="name"
                                placeholder="Your Name"
                                type="text"
                                className="bg-transparent"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="field yCenter gap-2">
                        <FontAwesomeIcon icon={faPhoneVolume} className='icon'/>
                            <Field
                                id="mobike"
                                name="mobile"
                                placeholder="Your Mobile Number"
                                type="number" // Sept 22 while making final
                                className="bg-transparent"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="field yCenter gap-2">
                        <FontAwesomeIcon icon={faEnvelope} className='icon'/>
                            <Field
                                id="email"
                                name="email"
                                placeholder="Your Email"
                                type="email"
                                className="bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="field d-flex gap-2">
                        <FontAwesomeIcon icon={faMessage} className='icon' style={{marginTop: "4px"}}/>
                            <Field
                                rows="4"
                                id="message"
                                name="message"
                                placeholder="Message..."
                                as="textarea"
                                type="text" // Sept 22 while making final
                                className="bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <button type="submit"  className='gradientBg border-0 w-100 py-2 text-white fw-bold' width="100%">Send Meesage</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default ContactForm
