import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./Posts.css"
import { fetchPosts } from '../../functions'
import TileShimmer from '../shimmer/TileShimmer'

function Posts() {

    const tempPosts = [null, null, null,null,null,null,null,null,null,null,null];

    const btnIcon = {
        icon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/button-arrow-linear.svg",
        btnText: "Explore More",
    }
    const [posts,setPosts] = useState(tempPosts);
    const [icon] = useState(btnIcon);
    const [postsCount,setPostsCount] = useState(5);


    useEffect(() => {
        const getData =async ()=>{
            const posts = await fetchPosts(postsCount);
            if(posts){
                setPosts(posts);
            }
        }
        getData();
    }, [postsCount])
    

    return (
        <div className={`posts xCenter gap-5 content-element ${posts[0] ? 'loaded' : ''} `}>
            {posts.map((post,index) => {
                if(post){
                    const postContent = JSON.parse(post['content']);
                    return (
                        <div key={"postList"+index} className="post xCenter gap-2 gap-md-3">
                            <div className="thumbnail position-relative" style={{overflow: "hidden"}}>
                                <div className="date position-absolute mt-2 ms-2 px-2 px-md-3 py-2 gradientBg allCenter text-white fw-semibold fs-6" style={{zIndex: "9", boxShadow: "0 5px 20px rgba(0,0,0,0.4)"}}>
                                    <p className='m-0'>{postContent.postDate}</p>
                                </div>
                                <Link to={"/"+post.slug}>
                                    <div className="imgWrapper postFeatureImage" >
                                        <img src={postContent.thumbnail} alt="thumbnail" className='w-100 transition' />
                                    </div>
                                </Link>
                            </div>
                            <div className='name_comments gradientText yCenter gap-5 w-md-75'>
                                <p className="m-0 author yCenter gap-2 flex-nowrap">
                                    <FontAwesomeIcon icon={faUser} className='text-black blogIcon' />
                                    By {postContent.author}
                                </p>
                                {/* <p className="m-0 comments yCenter gap-2 flex-nowrap">
                                    <FontAwesomeIcon icon={faCommentDots} />
                                    {postContent.comments}
                                    Comments
                                </p> */}
                            </div>
                            <div className="content xCenter gap-2">
                                <Link className='postTitle' to={"/"+post.slug}>
                                    <h2 className="h3 transition position-relative fw-bold gradientText heading">{postContent.title}</h2>
                                </Link>
                                <p className='m-0 fw-medium text-secondary truncateText'>{postContent.preview.toString().slice(0,130)+"..."}</p>
                                {/* <p className='m-0 fs-5 fw-medium text-secondary toggleText'></p> */}
                            </div>
                            <Link className='postTitle' to={"/"+post.slug}>
                                <button type='button' className='moreBtn transition border-0 bg-white yCenter gap-2 mb-3' style={{width: "180px"}}>
                                    <span className="imgWrapper d-inline-block" style={{ width: "45px" }}>
                                        <img src={icon.icon} alt="next" className='w-100 h-100' />
                                    </span>
                                    <span className='fw-semibold'>{icon.btnText}</span>
                                </button>
                            </Link>
                            {(index === posts.length-1) ?
                                <div className='d-flex gap-4 align-items-center m-auto'>
                                    <button onClick={()=>{setPostsCount(postsCount+5)}} type='button' className='moreBtn transition border-0 bg-white yCenter gap-2 mt-4'>
                                        <span className='fw-semibold gradientBg text-white rounded-1 p-3'>Load More</span>
                                    </button>
                                </div>
                            : true}
                        </div>
                    )
                }else{
                    return <TileShimmer  key={"postList"+index}/>
                }
            })}
        </div>
    )
}

export default Posts
