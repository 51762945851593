import React from 'react'
import "./ContactAll.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeSquare, faMessage, faPhone, faUser } from '@fortawesome/free-solid-svg-icons'
import { Formik, Field, Form } from 'formik';
import { submitForm, togglePopup } from '../../functions';

function ContactAll({data}) {
    return (
        <div className='contactAll' >
            <div className="col-10 titlesection">
                <div className=''>
                {(data.title) ? 
                <>
                    <h2 className='h1 titleFont' dangerouslySetInnerHTML={{ __html: (data.title) }} />
                    <p className='h6 text-secondary'>{data.text}</p>
                </>
                :
                <>
                    <h2 className='h1 titleFont' dangerouslySetInnerHTML={{ __html: (data.formData.title) }} />
                    <p className='h6 text-secondary'>{data.formData.text}</p>
                </>
            }
            </div>
            </div>
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    mobile: '',
                    message: '',
                }}
                onSubmit={async (values) => {
                    await submitForm(values);
                    // Thanku Popup Here function Here
                    togglePopup(document.getElementById("thankuPopupCover"),true);
                }}
            >
                <Form className="mt-4 pt-3 row row-gap-3 justify-centent-center">
                    <div className="col-10">
                        <div className="field yCenter gap-2">
                            <FontAwesomeIcon icon={faUser} className='fs-5' style={{color: "var(--red"}}/>
                            <Field
                                id="name"
                                name="name"
                                placeholder="Your Name"
                                type="text"
                                className="bg-transparent"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-10">
                        <div className="field yCenter gap-2">
                        <FontAwesomeIcon icon={faPhone} className='fs-5' style={{color: "var(--red"}}/>
                            <Field
                                id="mobike"
                                name="mobile"
                                placeholder="Your Mobile Number"
                                type="text"
                                className="bg-transparent"
                                required
                            />
                        </div>
                    </div>
                    <div className="col-10">
                        <div className="field yCenter gap-2">
                        <FontAwesomeIcon icon={faEnvelopeSquare} className='fs-5' style={{color: "var(--red"}}/>
                            <Field
                                id="email"
                                name="email"
                                placeholder="Your Email"
                                type="email"
                                className="bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="col-10">
                        <div className="field d-flex gap-2 ">
                        <FontAwesomeIcon icon={faMessage} className='fs-5' style={{marginTop: "4px",  color: "var(--red"}}/>
                            <Field
                                rows="6"
                                id="message"
                                name="message"
                                placeholder="Message..."
                                as="textarea"
                                className="bg-transparent"
                            />
                        </div>
                    </div>
                    <div className="col-10">
                        <button type="submit"  className='gradientBg border-0 w-100 py-2 text-white fw-bold' width="100%">Send Meesage</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default ContactAll
