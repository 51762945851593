import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import {createBrowserRouter,Outlet,RouterProvider, useLocation,} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Blogs from './pages/Blogs';
import ContactUs from './pages/ContactUs';
import NotFoundPage from './pages/NotFoundPage';
import Cursor from './components/cursor/Cursor';
import Thankyou from './components/popups/Thankyou';
import PostNotFound from './pages/PostNotFound';
import WhatsAppCta from "./components/popups/whatsAppCta/WhatsAppCta";
import DynamicRoutes from './pages/DynamicRoutes';


const AppLayout = ()=>{
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
        <Navbar/>
        <Outlet/>
        <Footer/>
        <Cursor />
        <Thankyou/>
        <WhatsAppCta />
    </>
  )
}
const router = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children:[
        {
            path: "/",
            element: <Home/>
        },
        {
            path: "/services",
            element: <Services/>
        },
        {
            path: "/blog",
            element: <Blogs/>
        },
        {
            path: "/contact-us",
            element: <ContactUs/>
        },
        {
            path: "/post/post-not-found",
            element: <PostNotFound/>
        },
        {
          path: "/products/product-not-found",
          element: <NotFoundPage />
        },
        {
          path: '*',
          element: <DynamicRoutes/>
        }
      ]
    },
]);


  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider
    router={router}
  />
);

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
