import React,{ useState,useEffect } from 'react'
import TileShimmer from '../shimmer/TileShimmer'
import { faAngleRight, faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./Products.css"
import {Link} from 'react-router-dom'


function Products({data}) {
  
    var datas = {...data}
     console.log("data===>products",datas);
   
    return (
    <div className='container-fluid my-5 productsPage'>
      <div className="container py-3">

        <div className="row ">
            <div className="col-12 allCenter">
            <h5 className="gradientText yCenter gap-2 text-capitalize">
                <FontAwesomeIcon icon={faSquare} className="square" />
                {data.subTitle}
                <FontAwesomeIcon icon={faSquare} className="square" />
            </h5>
            <h2 className="h1 titleFont text-uppercase" dangerouslySetInnerHTML={{ __html: datas.title }} />
            </div>
        </div>

        <div className="row justify-content-center row-gap-4 mt-5">

            {data.products.map((product, index) => {
                return(

                    <div key={"product"+index} className="col-sm-6 col-lg-4  ">
                        {product ? 
                         <Link target="_blank" to={product.slug ? product.slug : ""} className="text-decoration-none" >
                        {/* // <Link to={product.slug ? "/products/"+product.slug : "testing"} className="text-decoration-none" > */}
                              {/* <div className="text-decoration-none product"> */}

                              <div className="xCenter transition gap-4 p-5 h-100 step">
                                    <div className="vector " style={{width: "120px", maxWidth: "120px", maxHeight: "50px" ,borderRadius:"10px"}}><img src={product.vector} alt="vector" className='w-100 h-100' /></div>
                                    <div className="titles xCenter gap-2">
                                        <h2 className="m-0 title">{product.title}</h2>
                                         <p className="m-0">{product.subTitle}</p>
                                    </div>
                                    <div className="btnWrapper">
                                        <button className="btn knowMoreBtn gradientBg border-0 text-white rounded-1 fw600 yCenter gap-2">
                                            <p className="m-0">{data.moreBtnText}</p>
                                            <span className="rightCaret allCenter transition"><FontAwesomeIcon icon={faAngleRight} /></span>
                                        </button>
                                    </div>

                                    {product.featuredText ? 
                                        <p className="featuredBanner gradientBg text-white text-uppercase position-absolute m-0 top-0 end-0 label" style={{fontSize: "0.8rem", padding: "5px 8px 3px 8px"}}>{product.featuredText}</p>
                                    :
                                        ""
                                    }   

                                </div>
                              {/* </div> */}
                              
                            </Link>
                        :
                        <TileShimmer />
                    }
                    </div>
                )
            })}

        </div>

      </div>
    </div>
  )
}

export default Products
