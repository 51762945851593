import { faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./Portfolio.css"
import PostShimmer from '../shimmer/PostShimmer'

function Portfolio({data}) {
    return (
        <div className='container portfolio py-3'>
            <div className="row">
                <div className="col-12 allCenter">
                    <h5 className="gradientText yCenter gap-2">
                        <FontAwesomeIcon icon={faSquare} className="square" />
                        {data.subTitle}
                        <FontAwesomeIcon icon={faSquare} className="square" />
                    </h5>
                    <h2 className="h1 titleFont" dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
            </div>
            <div className="row pt-5 mt-3 justify-content-evenly">
                {data.cards.map((card,index)=>{
                    const styleObj = {
                        backgroundImage : (card)?`url(${card.backgroundImage})` : ""
                    }
                    return (
                        (card) ?
                        <div key={"profolio"+index} className="col-10 col-sm-6 col-md-4 col-lg p-0 poster allCenter text-light top">
                            <div className="img transition " style={styleObj}></div>
                            <div className="content p-4 p-sm-3 transition allCenter gap-4">
                                <h2 className='h3 fw-bolder'>{card.title}</h2>
                                <h5 className='h5 fw-semibold'>{card.subTitle}</h5>
                                {/* maximum length of description is 137 charactes */}
                                <p>{card.description}</p>
                            </div>
                        </div>
                        :
                        <div key={"profolio"+index} className="col-12 col-sm-6 col-md-4 col-lg p-0 poster allCenter text-light top">
                            <PostShimmer/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Portfolio
