import React from 'react'

function Tags({tags}) {

    return (
        <div className=' p-3 p-md-4 border xCenter gap-3'>
            <h2 className='m-0 fs-4 fw-bold'>Tags:</h2>
            <div className="tags ">
                {tags.map((tag,index) => {
                    return (
                        <span key={"postTags"+index} className='fw-semibold fs-6'>{tag.replace('-',' ').toUpperCase() + ", "}</span>
                    )
                })}
            </div>
        </div>
    )
}

export default Tags
