import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ServiceDetails from './ServiceDetails';
import Post from './Post';
import { fetchPage, fetchPostContent } from '../functions';
import NotFoundPage from './NotFoundPage';

function DynamicRoutes() {
    const { pathname } = useLocation();
    const tempData ={
        hero : null,
        aboutService: null,
        faq: null,
        moreServices: null
    }
    const [whichPage,setwhichPage] = useState(0);
    const [pageData,setPageData] = useState(tempData);
    const [head,setHead] = useState(null);
    useEffect(() => {
        const getData =async ()=>{
            setPageData(tempData);
            setwhichPage(0);
            const service = pathname.split('/').pop(); 
            const dataService = await fetchPage('dizzi_services_'+service);
            if(dataService){
                setPageData(dataService);
            }else{
                const postContentData = await fetchPostContent(service);
                if(postContentData.status !== 404){
                    setwhichPage(1);
                    setPageData(postContentData)
                }else{
                    setwhichPage(2);
                }
            }
            let head = await fetchPage('dizzi_services_'+service+'_head');
            if(head){
                setHead(head);
            }
        }
        getData();
        
    },[pathname]);

    if(whichPage === 0){
        return <ServiceDetails serviceDetailData={pageData} head={head}/>
    }else if(whichPage === 1){
        return <Post data={pageData} />
    }else{
        return <NotFoundPage/>
    }
}

export default DynamicRoutes