import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import "../posts/Posts.css"
import { fetchPosts } from '../../functions'
import PostShimmer from '../shimmer/PostShimmer'
import { Link } from 'react-router-dom'


function ErrorPagePosts() {

    const tempPosts = [null];

    const btnIcon = {
        icon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/button-arrow-linear.svg",
        btnText: "Explore More",
    }
    const [posts,setPosts] = useState(tempPosts);
    const [icon] = useState(btnIcon);
    const [postsCount] = useState(4);


    useEffect(() => {
        const getData =async ()=>{
            const posts = await fetchPosts(postsCount);
            setPosts(posts);
        }
        getData();
    }, [postsCount])

    return (
        <div id='postsContainer' className='posts row row-gap-4 row-gap-md-5'>
            {posts.map((post, index) => {
                if(post !== null){
                    const postContent = JSON.parse(post['content']);
                    return (
                        <div key={"post"+index} className="post d-flex flex-column gap-2 gap-md-3 col-md-6 col-lg-4">
                            <div className="thumbnail position-relative" style={{overflow: "hidden"}}>
                                <div className="date position-absolute mt-2 ms-2 p-2 gradientBg allCenter text-white fw-semibold fs-5" style={{zIndex: "9", boxShadow: "0 5px 20px rgba(0,0,0,0.4)"}}>
                                    <p className='m-0'>{postContent.postDate}</p>
                                </div>
                                <Link to={"/post/"+post.slug}>
                                    <div className="imgWrapper transition postFeatureImage" >
                                        <img src={postContent.thumbnail} alt="thumbnail" width={"100%"} className='transition' />
                                    </div>
                                </Link>
                            </div>
                            <div className='name_comments gradientText yCenter gap-5 w-md-75'>
                                <p className="m-0 author yCenter gap-2 flex-nowrap">
                                    <FontAwesomeIcon icon={faUser} className='blogIcon' />
                                    By {" "+postContent.author}
                                </p>
                                {/* <p className="m-0 comments yCenter gap-2 flex-nowrap">
                                    <FontAwesomeIcon icon={faCommentDots} className='blogIcon' />
                                    {post.comments}
                                    Comments
                                </p> */}
                            </div>
                            <div className="content xCenter gap-2">
                                <Link className='text-decoration-none text-black' to={"/post/"+post.slug}>
                                    <h2 className="m-0 h4 heading transition position-relative fw-bold">{postContent.title}</h2>
                                </Link>
                                <p className='m-0 fw-medium text-secondary truncateText'>{postContent.preview.toString().slice(0,300)+"..."}</p>
                            </div>
                            <Link className='text-decoration-none text-black' to={"/post/"+post.slug}>
                                <button type='button' className='moreBtn transition border-0 bg-white yCenter gap-2 mt-3' style={{width: "180px"}}>
                                    <span className="imgWrapper d-inline-block" style={{ width: "45px" }}>
                                        <img src={icon.icon} alt="next" className='w-100 h-100' />
                                    </span>
                                    <span className='fw-semibold'>{icon.btnText}</span>
                                </button>
                            </Link>
                        </div>
                    )
                }else{
                    return <PostShimmer  key={"postList"+index}/>
                }
            })}
        </div>
    )
}

export default ErrorPagePosts