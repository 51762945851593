import React, { useEffect, useState } from 'react'
import "./Footer.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { Link } from 'react-router-dom'
import { fetchPage } from '../../functions';
import TileShimmer from '../shimmer/TileShimmer';

function Footer() {
    const [footerData,setFooterData] = useState();
    useEffect(() => {
        const getData =async ()=>{
            const data = await fetchPage('dizzi_footer');
            setFooterData(data);
        }
        getData()
    }, [])

    const makeHoveredIcon = (wrapper) => {
        const colors = wrapper.querySelectorAll("stop");
        colors.forEach(color => {
            color.setAttribute("stop-color", "#FFFFFF")
        })
    }

    const removeHoveredIcon = (wrapper) => {
        const colors = wrapper.querySelectorAll("stop");
        colors[0].setAttribute("stop-color", "#CD1A1A");
        colors[1].setAttribute("stop-color", "#FFA200");
    }

    return (
        (footerData) ? 
            <div className='container-fluid footer text-white'>
                <div className="row p-4 py-5">
                    <div className="col-lg-4 py-3 pb-5 allCenter">
                        <div className="company allCenter gap-1 gap-md-3 p-4 border border-secondary rounded-circle">
                            <Link to={"/"} className="logo"><img src={footerData.logo} alt="logo" className='w-75' /></Link>
                            <div className="style yCenter gap-3">
                                <span className="line"></span>
                                <span style={{width: "30px"}}><img src="https://admin.dizzibooster.com/public/images/vector.svg" alt="vector" className='w-100 h-100' /></span>
                                <span className='line'></span>
                            </div>
                            <p className='m-0 fw-semibold'>{footerData.text}</p>
                            <div className='icons yCenter gap-2 secondColor'>
                                <Link target='_blank' to={footerData.socialIcons ? footerData.socialIcons.facebook : ""} className='allCenter icon rounded-circle' onMouseOver={(e) => makeHoveredIcon(e.currentTarget)} onMouseLeave={(e) => removeHoveredIcon(e.currentTarget)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 496 493" fill="none">
                                        <path d="M496 248C496 111 385 0 248 0C111 0 0 111 0 248C0 371.78 90.69 474.38 209.25 493V319.69H146.25V248H209.25V193.36C209.25 131.21 246.25 96.88 302.92 96.88C330.06 96.88 358.44 101.72 358.44 101.72V162.72H327.16C296.36 162.72 286.75 181.84 286.75 201.45V248H355.53L344.53 319.69H286.75V493C405.31 474.38 496 371.78 496 248Z" fill="url(#paint0_linear_5_151)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_5_151" x1="0" y1="247" x2="496" y2="247" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#CD1A1A"/>
                                            <stop offset="1" stopColor="#FFA200"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </Link>
                                <Link target='_blank' to={footerData.socialIcons ? footerData.socialIcons.instagram : ""} className='allCenter icon rounded-circle' onMouseOver={(e) => makeHoveredIcon(e.currentTarget)} onMouseLeave={(e) => removeHoveredIcon(e.currentTarget)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 448 449" fill="none">
                                        <path d="M224.1 110C160.5 110 109.2 161.3 109.2 224.9C109.2 288.5 160.5 339.8 224.1 339.8C287.7 339.8 339 288.5 339 224.9C339 161.3 287.7 110 224.1 110ZM224.1 299.6C183 299.6 149.4 266.1 149.4 224.9C149.4 183.7 182.9 150.2 224.1 150.2C265.3 150.2 298.8 183.7 298.8 224.9C298.8 266.1 265.2 299.6 224.1 299.6ZM370.5 105.3C370.5 120.2 358.5 132.1 343.7 132.1C328.8 132.1 316.9 120.1 316.9 105.3C316.9 90.4999 328.9 78.4999 343.7 78.4999C358.5 78.4999 370.5 90.4999 370.5 105.3ZM446.6 132.5C444.9 96.6 436.7 64.7999 410.4 38.5999C384.2 12.3999 352.4 4.19995 316.5 2.39995C279.5 0.299951 168.6 0.299951 131.6 2.39995C95.8001 4.09995 64 12.2999 37.7001 38.5C11.4001 64.7 3.30005 96.4999 1.50005 132.4C-0.599951 169.4 -0.599951 280.3 1.50005 317.3C3.20005 353.2 11.4001 385 37.7001 411.2C64 437.4 95.7001 445.6 131.6 447.4C168.6 449.5 279.5 449.5 316.5 447.4C352.4 445.7 384.2 437.5 410.4 411.2C436.6 385 444.8 353.2 446.6 317.3C448.7 280.3 448.7 169.5 446.6 132.5ZM398.8 357C391 376.6 375.9 391.7 356.2 399.6C326.7 411.3 256.7 408.6 224.1 408.6C191.5 408.6 121.4 411.2 92 399.6C72.4 391.8 57.3001 376.7 49.4001 357C37.7001 327.5 40.4001 257.5 40.4001 224.9C40.4001 192.3 37.8001 122.2 49.4001 92.7999C57.2001 73.1999 72.3 58.0999 92 50.1999C121.5 38.4999 191.5 41.1999 224.1 41.1999C256.7 41.1999 326.8 38.5999 356.2 50.1999C375.8 57.9999 390.9 73.0999 398.8 92.7999C410.5 122.3 407.8 192.3 407.8 224.9C407.8 257.5 410.5 327.6 398.8 357Z" fill="url(#paint0_linear_5_155)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_5_155" x1="-5.74892e-07" y1="206.5" x2="448" y2="203" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#CD1A1A"/>
                                            <stop offset="1" stopColor="#FFA200"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </Link>
                                <Link target='_blank' to={footerData.socialIcons ? footerData.socialIcons.youtube : ""} className='allCenter icon rounded-circle' onMouseOver={(e) => makeHoveredIcon(e.currentTarget)} onMouseLeave={(e) => removeHoveredIcon(e.currentTarget)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 548 384" fill="none">
                                        <path d="M535.655 60.083C529.374 36.433 510.868 17.807 487.371 11.486C444.781 0 274 0 274 0C274 0 103.22 0 60.629 11.486C37.132 17.808 18.626 36.433 12.345 60.083C0.932983 102.95 0.932983 192.388 0.932983 192.388C0.932983 192.388 0.932983 281.826 12.345 324.693C18.626 348.343 37.132 366.193 60.629 372.514C103.22 384 274 384 274 384C274 384 444.78 384 487.371 372.514C510.868 366.193 529.374 348.343 535.655 324.693C547.067 281.826 547.067 192.388 547.067 192.388C547.067 192.388 547.067 102.95 535.655 60.083ZM218.145 273.591V111.185L360.884 192.39L218.145 273.591Z" fill="url(#paint0_linear_4_138)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_4_138" x1="0.999997" y1="192" x2="547" y2="192" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#CD1A1A"/>
                                            <stop offset="1" stopColor="#FFA200"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </Link>
                                <Link target='_blank' to={footerData.socialIcons ? footerData.socialIcons.twitter : ""} className='allCenter icon rounded-circle' onMouseOver={(e) => makeHoveredIcon(e.currentTarget)} onMouseLeave={(e) => removeHoveredIcon(e.currentTarget)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 512 416" fill="none">
                                        <path d="M459.37 103.716C459.695 108.264 459.695 112.813 459.695 117.361C459.695 256.081 354.112 415.919 161.137 415.919C101.685 415.919 46.457 398.7 0 368.813C8.447 369.787 16.568 370.112 25.34 370.112C74.395 370.112 119.553 353.544 155.614 325.28C109.482 324.305 70.822 294.092 57.502 252.508C64 253.482 70.497 254.132 77.32 254.132C86.741 254.132 96.163 252.832 104.934 250.559C56.853 240.812 20.791 198.579 20.791 147.574V146.275C34.76 154.072 51.005 158.945 68.222 159.594C39.958 140.751 21.441 108.589 21.441 72.203C21.441 52.711 26.638 34.843 35.735 19.249C87.39 82.924 165.035 124.507 252.1 129.056C250.476 121.259 249.501 113.138 249.501 105.016C249.501 47.188 296.283 0.0820312 354.435 0.0820312C384.648 0.0820312 411.937 12.752 431.105 33.219C454.82 28.671 477.561 19.899 497.704 7.87903C489.906 32.245 473.338 52.712 451.572 65.706C472.689 63.433 493.156 57.584 511.998 49.463C497.706 70.254 479.837 88.771 459.37 103.716Z" fill="url(#paint0_linear_5_153)"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_5_153" x1="-9.53603e-06" y1="208" x2="488" y2="208" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#CD1A1A"/>
                                            <stop offset="1" stopColor="#FFA200"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 gx-0 py-3 py-sm-2 px-2 ps-3 text-center text-sm-start gap-3">
                                <div className="xCenter links">
                                    <h2 className='h3 mb-2 fw-bold'>{footerData.sections[0].title}</h2>
                                    {
                                        footerData.sections[0].links.map((link,index)=>{
                                            return <Link key={"section1Link"+index} to={link.link} className='m-0 mb-2 textHoverEffect'>{link.name}</Link>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="links col-sm-6 col-lg-4 gx-0 py-3 py-sm-2 px-2 ps-3 text-center text-sm-start gap-4">
                                <h2 className='h3 mb-1 fw-bold'>{footerData.sections[1].title}</h2>
                                <div>
                                    <div className="icons"></div>
                                    <div className="link xCenter">
                                        <Link to={`mailto:${footerData.sections[1].mail1}`} className='textHoverEffect m-0 mb-2'>{footerData.sections[1].mail1}</Link>
                                        <Link to={`mailto:${footerData.sections[1].mail2}`} className='textHoverEffect m-0 mb-2'>{footerData.sections[1].mail2}</Link>
                                    </div>
                                </div>
                                <div>
                                    <div className="icons"></div>
                                    <Link to={footerData.sections[1].map} className='textHoverEffect m-0 address'>{footerData.sections[1].address}</Link>
                                </div>
                                <Link target='_blank' to={footerData.sections[1].map}><button className='border py-3 px-4 mt-3 h5 bg-black gradientText transition ftBtn'>{footerData.sections[1].btnText}</button></Link>
                            </div>
                            <div className="col-sm-6 col-lg-4 gx-0 py-3 py-sm-2 px-2 ps-3 text-center text-sm-start gap-3">
                                <div className="links xCenter">
                                    <h2 className='h3 mb-2 fw-bold'>{footerData.sections[2].title}</h2>
                                    {
                                        footerData.sections[2].links.map((link,index)=>{
                                            return <Link key={"section3Link"+index} to={link.link} className='m-0 mb-2 textHoverEffect'>{link.name}</Link>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row yCenter pt-4">
                            <div className="col-xl-3 text-center fs-4 m-0 fw-bold"><p>{footerData.slideTitle}</p></div>
                            <div className="col-xl-9 pt-3 pt-lg-0 yCenter justify-content-sm-evenly justify-content-lg-start gap-5">
                                <Splide options={{
                                    perPage: 4,
                                    perMove: 1,
                                    // focus: "center",
                                    // gap: 1,
                                    lazyLoad: true,
                                    keyboard: true,
                                    direction: "ltr",
                                    speed: 500,
                                    type: "loop",
                                    drag: true,
                                    snap: true,
                                    autoplay: true,
                                    interval: 1500,
                                    arrows: false,
                                    pagination: false,
                                    breakpoints: {
                                        640: {
                                            perPage: 3,
                                        },
                                        360: {
                                            perPage: 1,
                                        },
                                    }
                                }} aria-label='Achievments'>
                                    {footerData.slides.map((slide,index) => {
                                        return (
                                            <SplideSlide key={"footer"+index} >
                                                <div className="slide allCenter w-100">
                                                    <div className="imgHolder"><img src={slide.img} alt="achievment" className='w-100 h-100' /></div>
                                                    {/* Title needed to remove comment when image has not title  */}
                                                    {/* <p className='m-0 title'>{footerData.slides[1].title}</p> */}
                                                </div>
                                            </SplideSlide>
                                        )
                                    })}
                                </Splide>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row px-5 allCenter">
                    <div className="col-12 p-4 border-top border-secondary">
                        <p className='m-0 fw-light titleFont fontSize' style={{fontSize : "16px"}} dangerouslySetInnerHTML={{ __html: footerData.footerText }} />
                    </div>
                </div>
            </div>
        :
            <TileShimmer/>
            
    )
}

export default Footer