import React, { useEffect, useState } from 'react'
import HeroBasic from '../components/heroBasic/HeroBasic'
import Recent from '../components/recent/Recent'
import ContactForm from '../components/contactForm/ContactForm'
import SubscribeBar from '../components/subscribBar/SubscribeBar'
import PostNotAvailable from '../components/postNotAvailable/PostNotAvailable'
import Posts from '../components/posts/Posts'
import { fetchPage } from '../functions'

function PostNotFound() {

    const tempData = {
        pagination :{
            prevIcon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/arrow-prev-one.svg",
            prevBtn: "Previous Post",
            nextBtn: "Next Post",
            nextIcon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/arrow-next-one.svg"
        },
        hero : {
            img: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/banner/banner-five/banner5-2.jpg",
            title: "Blog Details",
            main: "Home",
            currentPg: "Page Details",
        },
        notFoundContent: {
            title: "Oops! Something went wrong!",
            subTitle: "The page you are looking for doesn't exist. Try another page url and Trending Posts below",
            text: "Link is broken or not found"
        }
    }
    
    const [postData,setPostData] = useState(tempData);

    useEffect(() => {
        const getData = async ()=>{
            const postData = await fetchPage("dizzi_single_post");
            setPostData(postData);
            // console.log(postData);
        }
        getData();
    }, [postData])

    const postsTitle = "Trending Posts"; // Sept 9, 23

    return (
        <div className='post container-fluid'>
            <div className="row hero">
            {/* Hero section here  */}
            <HeroBasic data={postData.hero}/>
            </div>
            <div className="row blog-shortComponents px-2 px-md-5 py-2 py-md-4">
                <div className="col-lg-8 px-2 px-md-5 py-2 py-md-4">
                    <div className="notFoundError">
                        <PostNotAvailable data={postData.notFoundContent} />
                    </div>
                    <div className='my-4 xCenter gap-3'>
                        <h2 className="m-0 fs-4 fw-bold">{postsTitle}</h2>
                        <div className="trendingPostsContainer row row-gap-3 row-gap-sm-4">
                            <div className="col">
                                <Posts />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 py-2 py-md-4">
                    <div className="row row-gap-5">
                        <div className="col-md-6 col-lg-12 p-3 p-md-4 border recent"><Recent/></div>
                        <div className="col-md-6 col-lg-12 p-3 p-md-4 contact" style={{backgroundColor: "#fff9ee"}}><ContactForm/></div>
                    </div>
                </div>
            </div>
            <div className="row pt-3 subscribe"><SubscribeBar/></div>
        </div>
    )
}

export default PostNotFound
