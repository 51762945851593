import Hero from "../components/hero/Hero";
import About from "../components/about/About";
import Services from "../components/services/Services";
import Process from "../components/process/Process";
// import Team from "../components/team/Team";
import Testimonial from "../components/testimonial/Testimonial";
import Portfolio from "../components/portfolio/Portfolio";
import Contact from "../components/contact/Contact";
import { useEffect, useState } from "react";
import { fetchPage } from "../functions";
import HeadTag from "../components/headTag/HeadTag";
import Products from "../components/products/Products";
import JoinNowPopup from "../components/popups/JoinNowPopup";


function Home() {


  const tempData ={
    hero: null,
    about: {
      img: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/bg/about-five-image.jpg",
      title: "Know <span class='gradientText'>About </span>Us",
      info: "Get to Know",
      text: "We denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness",
      heading: "Our Achievements:",
      icon: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/icons/button-arrow-linear.svg",
      button: "Explore More",
      awards: [null, null, null, null, null]
    },
    services: {
      title: "Our <span class='gradientText'>Services</span> Area",
      subTitle: "What We Do",
      serviceCards: [null, null, null]
    },
    workingprocess: {
      title: "How Do We <span class='gradientText'>Work</span>",
      subTitle: "Working Process",
      processCards: [null, null, null, null]
    },
    products: {
      title: "FEATURES PRODUCTS",
      products: [null, null, null],
      moreBtnText: "Know More"
    },
    team: {
      title: "Our Working<span class='gradientText'>Team</span>",
      subTitle: "Working Team",
      teamCards: [null, null, null, null]
    },
    testimonial: {
      title: "Truly Clients <span class='gradientText'>Feedback<span>",
      subTitle: "What Clients Say",
      line: "img/line.svg",
      feedbackCards: [null, null, null, null]
    },
    portfolio: {
      title: "Our Awesome <span class='gradientText'>Portfolio</span>",
      subTitle: "Portfolio",
      cards: [null, null, null, null, null, null]
    },
    contact: {
      img: "img/girl.png",
      social: "img/social.png",
      formData: {
        title: "<span class='gradientText'>Contact</span> Us Now",
        text: "Certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures.",
      }
    }
  }
  const [homeData, setHomeData] = useState(tempData);
  const [head, setHead] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const getData = async () => {
      const data = await fetchPage('dizzi_home');
      if (data) {
        setHomeData(data);
      }
      const head = await fetchPage('dizzi_home_head');
      if(head){
        setHead(head);
      }
    }
    getData()

    setTimeout(() => {
      setShowPopup(true)
    }, 1500);

  }, [])



  return (
    <>
      {
        (showPopup) ? <JoinNowPopup /> : <></>
      }
      <HeadTag data={head} />
      <Hero data={homeData.hero} />
      <About data={homeData.about} />
      <Services data={homeData.services} />
      <Process data={homeData.workingprocess} />
      <Products data={homeData.products} />
      {/* <Team data={homeData.team}/> */}
      <Testimonial data={homeData.testimonial} />
      <Portfolio data={homeData.portfolio} />
      <Contact data={homeData.contact} />
    </>
  );
}

export default Home;
