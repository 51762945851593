import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faXmark, faPhone, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import "./Navbar.css"
import { fetchPage } from '../../functions'

function Navbar() {

    const tempData = {
        logo: "https://dizzibooster.com/img/logo.png",
        navLinks: [
            {
                linkText: "Home",
                linkAddress: "/",
                target : "_self",
            },
            {
                linkText: "Services",
                linkAddress: "/services",
                dropdown: [
                    {
                        text: "Search Engine Optimization",
                        address: "/services",
                        target : "_self",
                    },
                    {
                        text: "Digital Marketing",
                        address: "/services",
                        target : "_self",
                    },
                    {
                        text: "Google Ads",
                        address: "/services",
                        target : "_self",
                    },
                    {
                        text: "Business Consulting",
                        address: "/services",
                        target : "_self",
                    },
                    {
                        text: "Social Media Marketing",
                        address: "/services",
                        target : "_self",
                    },
                ]
            },
            {
                linkText: "Blog",
                linkAddress: "/blog",
                target : "_self",
            },
            {
                linkText: "Contact Us",
                linkAddress: "/contact",
                target : "_self",
            },
        ],
        mailIcon: "",
        mailText: "Email Now",
        mailAddress: "",
        callText: "Call Now",
        callAddress: ""
    }

    function toggle() {
        var elem = document.querySelector(".navCollapse");
        elem.classList.toggle("notVisible");
    }

    function showDropdown(link) {
        const dropdown = link.nextElementSibling;
        const caret = link.querySelector(".dropIcon");
        dropdown.classList.toggle("activeDropdown");
        caret.classList.toggle("rotateCross");
        
    }

    // Navbar Effect 
    const [color, setColor] = useState(false);
    const changeColor = () => {
        if(window.scrollY >= 100) {
            setColor(true);
        } else {
            setColor(false);
        }
    }
    window.addEventListener("scroll", changeColor);

    const [navbarData,setNavbarData] = useState(tempData);
    useEffect(() => {
        const getData =async ()=>{
            const data = await fetchPage('dizzi_navbar');
            if(data){
                setNavbarData(data);
            }
        }
        getData()
        const links = document.querySelectorAll(".collapseLink");
        links.forEach(link => {
            link.addEventListener("click", toggle);
        })
        
    }, [])


    return (
        <nav className={color ? "navbar navbar-expand-lg sticky-top scrollNav" : "navbar navbar-expand-lg  sticky-top coloredNav"}>
            <div className="container">
                <Link to={"/"}>
                    <img src={navbarData.logo} alt="logo" width={"200px"}     className='navbar-brand' />
                </Link>                
                <button className="navbar-toggler " type="button" onClick={toggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-2 mb-lg-0 gap-3 gap-lg-5">
                        {navbarData.navLinks.map((navLink,index) => {
                            if(navLink.dropdown){
                                return (
                                    <li key={"navbar"+index} data-toggle="collapse" data-target="" className="nav-item  dropdown position-relative">
                                        <Link target={navLink.target} to={navLink.linkAddress} className='text-black' style={{textDecoration:"none"}}>
                                            <span className="nav-link yCenter text-black" href="#" role="button" 
                                                // data-bs-toggle="dropdown" // Sept 13, 23
                                            aria-expanded="false">
                                                {navLink.linkText}
                                                <FontAwesomeIcon icon={faAngleDown} className='ms-2 navIcon' style={{color: "var(--red)", transition: "all 500ms ease-in"}}/>
                                            </span>
                                        </Link>
                                        <ul className="dropdownMenu top-dropdown-menu rounded-0 transition bg-black overflow-hidden d-block" id={navLink.linkText}>
                                            {navLink.dropdown.map((menu,index) => {
                                                return (
                                                    <li key={"navbarDropdown"+index}>
                                                        <Link target={menu.target} to={menu.address} className='dropdown-item px-2 py-1 text-white'>
                                                            {menu.text}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                );
                            }else{
                                return(
                                    <li key={"navbar"+index} className="nav-item">
                                        <Link target={navLink.target}  to={navLink.linkAddress} className='nav-link text-black'>
                                            {navLink.linkText}
                                        </Link>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                    <Link to={`mailto:${navbarData.mailAddress}`} className="d-flex  text-decoration-none">
                        <div className="h-100 pe-2">
                            <FontAwesomeIcon icon={faEnvelope} className='icon fs-4 text-white gradientBg rounded-circle p-3' />
                        </div>
                        <div className="mail">
                            <p className='m-0 fw-semibold text-black'>{navbarData.mailText}</p>
                            <p className='m-0 fs-5 fw-bold gradientText'>{navbarData.mailAddress}</p>
                        </div>
                    </Link>
                </div>
                <div className="navCollapse scrollBarHidden p-4 position-fixed start-0 transition notVisible">
                    <Link to={"/"} className="logo yCenter justify-content-between" style={{zIndex: "9999 !important"}}>
                        <img src={navbarData.logo} alt="logo" width={"150px"} className='navbar-brand' />
                        <FontAwesomeIcon icon={faXmark} className='fs-3 fw-light navCross cursorPointer' onClick={toggle} />
                    </Link>
                    <div className="linkSection xCenter py-4">
                        <ul className="navbar-nav mb-2 mb-lg-0 d-flex flex-column">
                            {navbarData.navLinks.map((navLink,index) => {
                                if(navLink.dropdown){
                                    return (
                                        <li  target={navLink.target}  key={"navbar"+index} className="nav-item dropdown position-relative">
                                            <span className="nav-link yCenter gap-4 text-black" href="#" role="button" onClick={(e) => showDropdown(e.currentTarget)} style={{transition: "all 400ms"}}>
                                                {navLink.linkText}
                                                <FontAwesomeIcon icon={faAngleDown} className='ms-2 dropIcon' style={{color: "var(--red)", transition: "all 500ms linear"}}/>
                                            </span>
                                            <ul className="dropdownMenuCollpase p-0 ms-3 top-dropdown-menu rounded-0 overflow-hidden collapseLink" id={navLink.linkText}>
                                                {navLink.dropdown.map((menu,index) => {
                                                    return (
                                                        <li key={"navbarDropdown"+index} >
                                                            <Link target={menu.target} to={menu.address} className='dropdown-item px-2 py-1'>
                                                                {menu.text}
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </li>
                                    );
                                }else{
                                    return(
                                        <li target={navLink.target}  key={"navbar"+index} className="nav-item collapseLink">
                                            <Link to={navLink.linkAddress} className='nav-link text-black'>
                                                {navLink.linkText}
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                    <div className="contact pt-3 xCenter gap-3  ">
                        <Link to={`mailto:${navbarData.mailAddress}`} className="d-flex  text-decoration-none">
                            <div className="h-100 pe-2">
                                <FontAwesomeIcon icon={faEnvelope} className='icon fs-4 text-white gradientBg rounded-circle p-3' />
                            </div>
                            <div className="mail">
                                <p className='m-0 fw-semibold text-black'>{navbarData.mailText}</p>
                                <p className='m-0 fs-5 fw-bold gradientText'>{navbarData.mailAddress}</p>
                            </div>
                        </Link>
                        <Link to={`tel:${navbarData.callAddress}`} className="call yCenter gap-2  text-decoration-none">
                            <div className="icon allCenter">
                                <FontAwesomeIcon icon={faPhone} className='icon gradientBg rounded-circle p-3 fs-5 text-white' />
                            </div>
                            <div className="content xCenter justify-content-between">
                                <p className='m-0 fw-semibold text-black'>{navbarData.callText}</p>
                                <p className='m-0 fs-5 fw-semibold gradientText'>{navbarData.callAddress}</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
