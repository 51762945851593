import React, { useEffect, useState } from 'react'

import ServicesHero from '../components/servicesHero/ServicesHero';
import Companies from '../components/companies/Companies';
import Assuarance from '../components/assuarance/Assuarance';
import ServicesComponent from "../components/services/Services";
import Contact from "../components/contact/Contact";
import { fetchPage } from '../functions';
import HeadTag from '../components/headTag/HeadTag';

function Services() {
  const tempData =
  {
    hero : {
      images: [

      ],
      subTitle: "What Dizzibooster Offers",
      title: "We offer you high quality <span class='gradientText'>Digital Services</span>",
      text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut, minus expedita. Tenetur, voluptatum quam. Molestiae magnam, eligendi aut voluptas quaerat quo accusamus deserunt, cum excepturi quam, libero beatae sunt nostrum!",
      progressBars: [null,null],
      btnText: "Get Started",
    },
    services: {
      title: "Our <span class='gradientText'>Services</span> Area",
      subTitle: "What We Do",
      serviceCards: [null,null,null]
    },
    companies : [null,null,null,null,null,null],
    assuarance:{
      subTitle: "How We Work For You",
      title: null,
    },
    contact:{
      img: "img/girl.png",
      social: "img/social.png",
      formData : {
        title: "<span class='gradientText'>Contact</span> Us Now",
        text: "Certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures.",
      }
    }

  } 
  const [servicesData,setServicesData] = useState(tempData);
  const [head,setHead] = useState(null);
  useEffect(() => {
    const getData =async ()=>{
      const data = await fetchPage('dizzi_services');
      setServicesData(data);
      const head = await fetchPage('dizzi_services_head');
      setHead(head);
    }
    getData()
    
  }, [])
  
  return (
    <>
      <HeadTag data={head}/>
      <ServicesHero data={servicesData.hero}/>
      <ServicesComponent data={servicesData.services} />
      <Companies data={servicesData.companies} />
      <Assuarance data={servicesData.assuarance}/>
      <Contact data={servicesData.contact}/>
    </>
  )
}

export default Services
