import React, { useEffect, useState } from 'react'
import { fetchCategories } from '../../functions';

function Category() {

    const [categories,setCategories] = useState([]);

    useEffect(() => {
      const getData =async ()=>{
          const categories = await fetchCategories();
          console.log(categories);
          setCategories(categories);
      }
      getData();
  }, [])
    

  return (
    <div className='xCenter gap-3'>
      <h2 className='m-0 fs-4 fw-bold'>Category:</h2>
      <ul className='p-0 m-0 xCenter gap-2' style={{listStyle: "none"}}>
        {categories.map((category,index) => {
            return(
              <li key={"category"+index} className='p-0 fw-semibold'>{(category.category).replace('-',' ').toUpperCase()}</li>
            )
        })}
      </ul>
    </div>
  )
}

export default Category