import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { togglePopup } from '../../../functions'
import "./Video.css"

function Video({videoUrl}) {
    return (
        <div id="videoPopupCover" className='d-none'>
            <div className="popupMask" style={{backgroundColor: "#000000cc"}}></div>
            <div className="popupCenter mainPopup mainVideoPopup"  style={{zIndex: "99999999", width: "600px", height: "350px"}}>
                <span className="closeIcon position-absolute bg-black text-white rounded-circle allCenter cursorPointer" style={{width: "30px", height: "30px", right: "-10px", top: "-10px"}} onClick={() => togglePopup(document.getElementById("videoPopupCover"), false)}><FontAwesomeIcon icon={faXmark} /></span>
                <div className="bg-white p-3 rounded-3 w-100 h-100" style={{ boxShadow: "0 5px 20px rgba(0,0,0,0.3)"}}>
                    <iframe className='w-100 h-100' src={videoUrl} title="The Flash - Most overpowered moments" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Video
