import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import TileShimmer from '../shimmer/TileShimmer'

function PageNotFound({data}) {
  if(data){
    return (
      <div className='pageNotFound allCenter gap-3'>
        <div className="imgWrapper" style={{width: "300px"}}><img src={data.image} alt="error-geting-page" className=' w-100 h-100"' /></div>
        <h2 className="title m-0" style={{color: "#081d3c", fontWeight: "900"}}>{data.title}</h2>
        <h2 className="subTitle m-0 fw-medium text-secondary">{data.subTitle}</h2>
        <a href={`tel:${data.contactNo}`} className="btnWrapper text-decoration-none">
          <button className='btn gradientBg text-white yCenter gap-2 border-0'>
              <span className="iconWrapper"><FontAwesomeIcon icon={faPhone} /></span>
              <p className="m-0">{data.btnText}</p>
          </button>
        </a>
      </div>
    )
  }else{
    return <TileShimmer/>
  }
}

export default PageNotFound
