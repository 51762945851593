const whatsAppCtaJs = () => {
    
    document.addEventListener("click", function (event) {
    if (event.target.classList.contains("informasi")) {
        document.getElementById("get-number").textContent = event.target.querySelector(".my-number").textContent;
        document.querySelectorAll(".start-chat, .get-new").forEach(function (element) {
        element.classList.add("show");
        element.classList.remove("hide");
        });
        document.querySelectorAll(".home-chat, .head-home").forEach(function (element) {
        element.classList.add("hide");
        element.classList.remove("show");
        });
        document.getElementById("get-nama").textContent = event.target.querySelector(".info-chat .chat-nama").textContent;
        document.getElementById("get-label").textContent = event.target.querySelector(".info-chat .chat-label").textContent;
    }
    });
    
    document.addEventListener("click", function (event) {
    if (event.target.classList.contains("close-chat")) {
        document.getElementById("whatsapp-chat").classList.add("hide");
        document.getElementById("whatsapp-chat").classList.remove("show");
    }
    });
    
    document.addEventListener("click", function (event) {
    if (event.target.classList.contains("blantershow-chat")) {
        document.getElementById("whatsapp-chat").classList.add("show");
        document.getElementById("whatsapp-chat").classList.remove("hide");
    }
    });      
}
const sendEvent = ()=>{
    var a = document.getElementById("chat-input");
    if ("" !== a.value) {
        var b = document.getElementById("get-number").textContent;
        var c = document.getElementById("chat-input").value;
        var d = "https://web.whatsapp.com/send";
        var e = b;
        var f = "&text=" + c;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            d = "whatsapp://send";
        }
        var g = d + "?phone=+919646700028" + e + f;
        window.open(g, "_blank");
    }
}
export {whatsAppCtaJs,sendEvent}