import { faCommentDots, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Quote from '../quote/Quote'
import PostImg from '../postImgs/PostImg'
import List from '../list/List'
import PostShimmer from '../shimmer/PostShimmer'
import TileShimmer from '../shimmer/TileShimmer'
import { faFacebook, faInstagram, faTelegram, faTwitter, faWhatsapp, faSnapchat } from '@fortawesome/free-brands-svg-icons';
import { share } from '../../functions'

function PostContent({data}) {
  const blogUrl = window.location.href;

  if(data){
    function decodeHtmlSpecialChars(html) {
      var parser = new DOMParser();
      var decodedString = parser.parseFromString(html, 'text/html').body.textContent;
      return decodedString;
    }

    return (
      <div key={"postContent"+blogUrl} className='postContent xCenter gap-2 gap-md-3'>
        <div className="thumbnail position-relative">
          <div className="data position-absolute mt-2 ms-2 p-2 p-md-3 gradientBg allCenter text-white fw-semibold fs-5">
            <p className='m-0'>{data.postDate}</p>
          </div>
          <div className="imgWrapper">
            <img src={data.thumbnail} alt="thumbnail" width={"100%"} />
          </div>
        </div>
        <div className='name_comments gradientText yCenter gap-5 w-md-75'>
          <p className="m-0 author yCenter gap-2 flex-nowrap">
            <FontAwesomeIcon icon={faUser} className='blogIcon' />
            By {data.author}
          </p>
          <p className="m-0 comments yCenter gap-2 flex-nowrap">
            <FontAwesomeIcon icon={faCommentDots} className='blogIcon' />
            {data.comments}
            Comments
          </p>
        </div>
        <div className="content">
          <h2 className="heading fw-bold">{data.title}</h2>
          <div dangerouslySetInnerHTML={{__html : decodeHtmlSpecialChars(data.text)}}></div>
          {/* {data.text.map((para, index) => {
            return (
              <>
                <p key={"postPara"+index} className='m-0 fs-5 text-secondary'>{para}</p>
                {index !== data.text.length-1 ? <br key={"postParaBreak"+index} /> : ""  }
              </>
            )
          })} */}
        </div>
        {
          Object.keys(data.additional).map((block,index)=>{
            if(block === "images"){
              return <PostImg key={block+index} images={data.additional.images} />
            }else if(block === "quote"){
              return(
                <div key={block+index} className="quote py-4">
                  <Quote quote={data.additional.quote} />
                </div>
              )
            }else if(block === "list"){
              return(
                <div key={block+index} className="listSection">
                  <List list={data.additional.list} />
                </div>
              )
            }else{
              return true;
            }
          })
        }
  
        <div className="tagSection">
          <div className="bar border-top border-bottom py-2 yCenter justify-content-between flex-wrap">
            <div className="tags d-inline">
              <p className='m-0 fs-5 fw-semibold d-inline'>Tags: &nbsp;</p>
              {data.tags.map((tag,index) => {
                return (
                  <h5 key={"tag"+index} className='fs-6 d-inline text-secondary'>{tag}, </h5>
                )
              })}
            </div>
            <div className="links yCenter flex-wrap">
              <p className='m-0 fs-5 fw-semibold '>Share: &nbsp;</p>
              <span className='icons yCenter gap-2 secondColor'>
                <span key={"postContentStar1"} className='allCenter icon rounded-circle p-1 cursorPointer' onClick={() => share("whatsapp", blogUrl, data.title)}><FontAwesomeIcon icon={faWhatsapp} /></span>
                <span key={"postContentStar2"} className='allCenter icon rounded-circle p-1 cursorPointer' onClick={() => share("telegram", blogUrl, data.title)}><FontAwesomeIcon icon={faTelegram} /></span>
                <span key={"postContentStar3"} className='allCenter icon rounded-circle p-1 cursorPointer' onClick={() => share("facebook", blogUrl, data.title)}><FontAwesomeIcon icon={faFacebook} /></span>
                <span key={"postContentStar4"} className='allCenter icon rounded-circle p-1 cursorPointer' onClick={() => share("twitter", blogUrl, data.title)}><FontAwesomeIcon icon={faTwitter} /></span>
                <span key={"postContentStar5"} className='allCenter icon rounded-circle p-1 cursorPointer' onClick={() => share("instagram", blogUrl, data.title)}><FontAwesomeIcon icon={faInstagram} /></span>
                <span key={"postContentStar6"} className='allCenter icon rounded-circle p-1 cursorPointer' onClick={() => share("snapchat", blogUrl, data.title)}><FontAwesomeIcon icon={faSnapchat} /></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }else{
    return (
      <>
        <PostShimmer key={"postContentShimmer1"} />
        <TileShimmer key={"postContentShimmer2"} />
        <br></br>
        <TileShimmer key={"postContentShimmer3"} />
        <br></br>
        <TileShimmer key={"postContentShimmer4"} />
        <br></br>
        <TileShimmer key={"postContentShimmer5"} />
        <br></br>
        <TileShimmer key={"postContentShimmer6"} />
        <br></br>
      </>
    )
  }
}

export default PostContent
