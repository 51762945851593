import React from 'react'
import Recent from '../recent/Recent'
import ContactForm from '../contactForm/ContactForm'
import "./RecentBlogs.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
// import Cursor from '../cursor/Cursor'

function RecentBlogs() {

    const data = {
        logo: "img/logo.png"
    }
    function toggle() {
        var recent = document.querySelector(".recentPosts");
        recent.classList.toggle("notVisible");
    }

    return (
        <div className='recentBlogs scrollBarHidden p-3 p-md-4 xCenter bg-white pt-0 gap-4 transition'>
            <div className="logo yCenter justify-content-between">
                <img src={data.logo} alt="logo" width={"50%"} />
                <span className='cursorPointer cursorFollower'>
                    <FontAwesomeIcon icon={faXmark} className='fs-3 fw-light' onClick={toggle} />
                    {/* <Cursor /> */}
                </span>
            </div>
            <Recent />
            <div className='p-3' style={{background: "#fff9ee"}}>
                <ContactForm />
            </div>
        </div>
    )
}

export default RecentBlogs
