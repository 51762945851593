import { faQuoteRight, faSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./About.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import TileShimmer from '../shimmer/TileShimmer';
import { Link } from 'react-router-dom'

function About({data}) {
    return (
        <div className='container aboutUs py-3'>
            <div className="row row-gap-4">
                <div className="col-lg-5 py-2  imageSection">
                    <div className="imgHolder  position-relative m-5 imageWitchBatch m-lg-0 ">
                        <div className='imageHover transition'>
                        <img src={data.img} alt="about" className='w-100 transition' />
                        </div>
                        <div className="contentBox positon-absolute allCenter p-2">
                            <h5 className='p fw-semibold'>Our Target to Esay Solution Of Busniess Progress & Customer Satisfaction</h5>
                            <FontAwesomeIcon icon={faQuoteRight} className='icon quoteIcon fs-2 position-absolute bottom-0 end-0 me-3 mb-2 gradientTextBottom'/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 py-lg-0 py-sm-3 px-3 xCenter">
                    <h5 className="gradientText yCenter gap-2">
                        <FontAwesomeIcon icon={faSquare} className="square" />
                        {data.info}
                        <FontAwesomeIcon icon={faSquare} className="square" />
                    </h5>
                    <h2 className="h1 titleFont" dangerouslySetInnerHTML={{ __html: data.title }} />
                    <p className='fs-6 m-0 my-3 text-secondary lh-base'>{data.text}</p>
                    <h4 className='fw-bold'>{data.heading}</h4>
                    <div className={`awards yCenter gap-0 py-3 text-center content-element ${data ? 'loaded' : ''} `}>
                        <Splide options={{
                                perPage: 4,
                                perMove: 1,
                                // focus: "center",
                                gap: 2,
                                lazyLoad: true,
                                keyboard: true,
                                direction: "ltr",
                                speed: 500,
                                type: "loop",
                                drag: true,
                                snap: true,
                                autoplay: true,
                                interval: 1500,
                                arrows: false,
                                pagination: false,
                                breakpoints: {
                                    640: {
                                        perPage: 3,
                                    },
                                    360: {
                                        perPage: 2,
                                    },
                                }
                        }} aria-label='Achievments' className='w-100'>
                                {data.awards.map((award,index) => {
                                    return(
                                        (award === null) ? 
                                        
                                            <TileShimmer key={"award"+index}/>
                                        
                                        :
                                        <SplideSlide key={"award"+index}>
                                            <div className="award allCenter row-gap-2">
                                                <div className="imgWrapper transition w-100 h-100">
                                                    <img src={award.awardImg} alt="award" className='w-50 h-100  imageHover' />
                                                </div>
                                                <h6 className='fw-bold'>{award.awardName}</h6>
                                            </div>
                                        </SplideSlide>
                                    )
                                })}
                            </Splide>
                    </div>
                    {
                        (data.icon && data.button) ?
                            <Link to="" className="blackLink yCenter m-0 mt-2 gap-2 fw-semibold">
                                <img src={data.icon} alt="icon" />
                                {data.button}
                            </Link>
                        : true
                    }
                </div>
            </div>
        </div>
    )
}

export default About
