import React, { useEffect, useState } from 'react'
import HeroBasic from '../components/heroBasic/HeroBasic'
import ContactAll from '../components/contactAll/ContactAll'
import WorkingHours from '../components/workingHours/WorkingHours'
import Companies from '../components/companies/Companies';
import Testimonial from "../components/testimonial/Testimonial";
import SubscribeBar from '../components/subscribBar/SubscribeBar'
// import Review from '../components/review/Review'
import { fetchPage } from '../functions';
import HeadTag from '../components/headTag/HeadTag';

function ContactUs() {
    const tempData = {
        hero : null,
        services : {
          heading: "Services",
          services: [null, null]
        },
        contact: {
            "img": "img/girl.png",
            "social": "img/social.png",
            "formData": {
                "title": "<span class='gradientText'>Contact</span> Us Now",
                "text": "Certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures."
            }
        },
        companies : [null,null,null,null,null,null],
        workings: null,
        testimonial:{
          title: "Truly Clients <span class='gradientText'>Feedback<span>",
          subTitle: "What Clients Say",
          line: "img/line.svg",
          feedbackCards: [null,null,null,null]
        },
    }
    
    const [contactData,setContactData] = useState(tempData);
    const [head,setHead] = useState(null);
    useEffect(() => {
        const getData =async ()=>{
            const data = await fetchPage('dizzi_contact');
            setContactData(data);
            const head = await fetchPage('dizzi_contact_head');
            setHead(head);
        }
        getData()
        
    }, [])
    return (
        <>
            <HeadTag data={head}/>
            <HeroBasic data={contactData.hero}/>
            <div className="bg-secondary py-3 py-lg-0">
                <div className="pt-3 px-2 px-sm-5 pt-sm-3">
                    <div className="row row-gap-4 px-3 px-md-5 pt-3 gx-0">
                        <div className="col-lg-7">
                            <div className=''>
                                <ContactAll data={contactData.contact} />
                            </div>
                        </div>
                        <div className="col-lg-5 py-md-5 ps-lg-4">
                            <div className='py-md-5'>
                                <div className='p-4 bg-white' style={{ boxShadow:'4px 4px 4px 4px #e8e8e8'}}>
                                    <WorkingHours data={contactData.workings} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <Companies data={contactData.companies}/>
            <div className='wrapper mt-3'>
                <Testimonial data={contactData.testimonial}/>
            </div>
            {/* <Review/> */}
            <SubscribeBar/>
        </>
    )
}

export default ContactUs
