import { faEnvelope, faPhoneVolume } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./WorkingHours.css"
import { Link } from 'react-router-dom'
import TileShimmer from '../shimmer/TileShimmer'

function WorkingHours({data}) {

    // const data = {
    //     "heading": "Working Hours",
    //     "workings": "Mon to Sat <span class='gradientText'>:</span> 8am - 9am",
    //     "closed": "Sunday <span class='gradientText'>: Closed</span>",
    //     "callBtn": {
    //         "text": "Call Now",
    //         "address": "+91 96467 00028"
    //     },
    //     "mailBtn": {
    //         "text": "E-mail Now",
    //         "address": "info@dizzibooster.com"
    //     }
    // }    

    if(data) {
        return (
            <div className='workingHours'>
                <div className="timing p-4 allCenter gap-2">
                    <h2 className='m-0 fs-4 fw-bold'>{data.heading}</h2>
                    <div className="style yCenter gap-3">
                        <span className="line gradientBg" style={{ height: "2px", width: "90px" }}></span>
                        <span style={{width: "20px"}}><img src="https://admin.dizzibooster.com/public/images/vector.svg" alt="vector" className='w-100 h-100' /></span>
                        <span className='line gradientBg' style={{ height: "2px", width: "90px" }}></span>
                    </div>
                    <p className='fs-5 text-responsive m-0 text-secondary fw-semibold' dangerouslySetInnerHTML={{__html: data.workings}} />
                    <p className='fs-5 text-responsive m-0 text-secondary fw-semibold' dangerouslySetInnerHTML={{__html: data.closed}} />
                </div>
                <hr />
                <div className="numbers pb-4 xCenter gap-3">
                    <Link to={"tel:"+data.callBtn.address} className="text-decoration-none number yCenter gap-2">
                        <div className='allCenter overFlowing bg-white rounded-circle p-2' style={{ border: "2px solid var(--red)", zIndex: "2" }}>
                            <FontAwesomeIcon icon={faPhoneVolume} className='fs-5' style={{ color: "var(--red)" }} />
                        </div>
                        <div className="content position-relative" style={{ top: "7px", right: "27px", zIndex: "1" }}>
                            <div className=' gradientBg overFlowing allCenter px-5' style={{clipPath: "polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%, 0% 0%)", width: "max-content"}}>
                                <p className=' m-0 text-white  fw-semibold'>{data.callBtn.text}</p>
                            </div>
                            <p className='m-0 text-responsive gradientText fs-5 fw-semibold position-relative' style={{ left: "20px" }}>{data.callBtn.address}</p>
                        </div>
                    </Link>
                    <Link to="mailto:learningsessions@gmail.com" className="text-decoration-none email yCenter gap-2">
                        <div className='allCenter overFlowing bg-white rounded-circle p-2' style={{ border: "2px solid var(--red)", zIndex: "2" }}>
                            <FontAwesomeIcon icon={faEnvelope} className='fs-5' style={{ color: "var(--red)" }} />
                        </div>
                        <div className="content position-relative" style={{ top: "7px", right: "27px", zIndex: "1" }}>
                            <div className='gradientBg overFlowing allCenter px-5' style={{clipPath: "polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%, 0% 0%)", width: "max-content"}}>
                                <p className='text-white m-0 fw-semibold'>{data.mailBtn.text}</p>
                            </div>
                            <p className='m-0 text-responsive gradientText fs-5 fw-semibold position-relative' style={{ left: "20px" }}>{data.mailBtn.address}</p>
                        </div>
                    </Link>
                </div>
            </div>
        )
    } else {
        return( <TileShimmer /> )
    }
}

export default WorkingHours
