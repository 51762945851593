import React from 'react'
import HeroBasic from '../components/heroBasic/HeroBasic'
import SubscribeBar from '../components/subscribBar/SubscribeBar'
import AboutService from '../components/aboutService/AboutService';
import FaqAboutService from '../components/faqAboutService/FaqAboutService';
import MoreServices from '../components/moreServices/MoreServices';
import HeadTag from '../components/headTag/HeadTag';

function ServiceDetails({serviceDetailData,head}) {

  return (
    <>
      <HeadTag data={head}/>
      <HeroBasic data={serviceDetailData.hero} />
      <div className='container my-5'>
          <div className="wrapper">
            <AboutService data={serviceDetailData.aboutService} />
          </div>
          <div className="faqContent wrapper mt-5">
            <FaqAboutService faqData={serviceDetailData.faq} />
          </div>
          <div className="moreServices wrapper mt-5">
            <MoreServices data={serviceDetailData.moreServices} />
          </div>
      </div>
      <SubscribeBar/>
    </>
  )
}

export default ServiceDetails
