import { faStar, faStarHalf,faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React ,{ CAvatar } from 'react'
import "./Testimonial.css"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import PostShimmer from "../shimmer/PostShimmer"


function Testimonial({data}) {
    return (
        <div className='container testimonial py-2 py-sm-4'>
            <div className="row justify-content-center">
                <div className="col-12 allCenter">
                    <h5 className="gradientText yCenter gap-2"><img src={data.line} alt="line" />{data.subTitle}<img src={data.line} alt="line" /></h5>
                    <h2 className="h1 titleFont" dangerouslySetInnerHTML={{ __html: data.title }} />
                </div>
                <div className="row py-3 xCenter justify-content-evenly">
                    <Splide  options={{
                        perPage: 3,
                        perMove: 1,
                        focus: "center",
                        gap: 3,
                        lazyLoad: true,
                        keyboard: true,
                        direction: "ltr",
                        speed: 500,
                        type: "loop",
                        drag: true,
                        snap: true,
                        autoHeight : true,
                        breakpoints: {
                            760: {
                                perPage: 2,
                            },
                            640: {
                                perPage: 1,
                            },
                        }
                    }} aria-label='My_Testimonials'>
                        {data.feedbackCards.map((feedbackCard, index) => {
                            if(feedbackCard && feedbackCard.review){
                                return (
                                    (feedbackCard) ?
                                    <SplideSlide key={"feedbackCard"+index} >
                                        <div className="h-95 col-md-6 col-lg-4 p-8 px-4 mb-sm-10 mt-3  feedbackCard allCenter bg-secondary  transition  border-white w-95 zoom testimonialsection">
                                        {/* <div className="h-100 col-md-4  feedbackCard allCenter bg-secondary  rounded  w-100 zoom testimonialsection"> */}
     
                                         <p className='m-0 mt-2 titleFont'>{feedbackCard.rating}</p>
                                            <span className='yCenter gap-1'>
                                                <FontAwesomeIcon icon={faStar} className='star' />
                                                <FontAwesomeIcon icon={faStar} className='star' />
                                                <FontAwesomeIcon icon={faStar} className='star' />
                                                <FontAwesomeIcon icon={faStar} className='star' />
                                                <FontAwesomeIcon icon={faStarHalf} className='star' />
                                            </span>
                                            <p className='m-0 h6 my-3 text-secondary review fw-semibold lh-base'>{feedbackCard.review}</p>
                                            <p className='m-0 h6 fw-bold'>{feedbackCard.name}</p>
                                            <p className='m-0 h6'>{feedbackCard.company}</p>
                                       
                                        </div>
                                    </SplideSlide>
                                    :
                                    <SplideSlide key={"feedbackCard"+index}>
                                        <div className="col-md-6 col-lg-4 p-4 px-4 mb-sm-3 feedbackCard allCenter bg-secondary rounded transition border border-white w-100">
                                            <PostShimmer/>
                                        </div>
                                    </SplideSlide>
                                )
                            }
                        })}
                    </Splide>
                </div>
            </div>
        </div>
    )
}

export default Testimonial