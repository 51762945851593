import React, { useEffect, useState } from 'react'
import PageNotFound from '../components/pageNotFound/PageNotFound'
import Services from '../components/services/Services'
import SubscribeBar from '../components/subscribBar/SubscribeBar'
import ErrorPagePosts from '../components/ErrorPagePosts/ErrorPagePosts'
import { fetchPage } from '../functions'
import HeadTag from '../components/headTag/HeadTag'

function NotFoundPage() {
  const tempData =
  {
    errorElement : null,
    services :{
      title: "Our <span class='gradientText'>Services</span> Area",
      subTitle: "What We Do",
      serviceCards: [null,null,null]
    },
  } 
  const [errorPageData,setErrorPageData] = useState(tempData);
  const [head,setHead] = useState(null);
  useEffect(() => {
    const getData =async ()=>{
      const data = await fetchPage('dizzi_error');
      setErrorPageData(data);
      const head = await fetchPage('dizzi_error_head');
      setHead(head);
    }
    getData()
    
  }, [])



  return (
    <>
      <HeadTag data={head}/>
      <div className='xCenter gap-2 mt-4 mb-5'>
        <div className='404 allCenter'>
          <PageNotFound data={errorPageData.errorElement}/>
        </div>
        <div className="services">
          <Services data={errorPageData.services}/>
        </div>
        <div className="container">
          <ErrorPagePosts />
        </div>
      </div>
      <SubscribeBar />
    </>
  )
}

export default NotFoundPage
