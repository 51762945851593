import React, { useEffect } from 'react'
import './Cursor.css'
import { useLocation } from 'react-router-dom';

function Cursor() {
    const {pathname} = useLocation();

    useEffect(() => {
        // cursor functioning 
        const cursor = document.querySelector('#cursor');
        document.addEventListener("mousemove", (e) => {
            cursor.style.left = `${e.pageX}px`;
            cursor.style.top = `${e.pageY}px`;
        });

        setTimeout(() => {
            // cursor showing 
            const elements = document.querySelectorAll(".cursorFollower");
            elements.forEach(element => {
                element.addEventListener("mousemove", () => {
                    cursor.classList.add("inAnimation");
                    cursor.style.display = "block";
                })
                element.addEventListener("mouseleave", () => {
                    cursor.classList.remove("inAnimation");
                    cursor.style.display = "none";
                })
            })
        }, 1000)

    }, [pathname])

  return (
    <div id="cursor"></div>
  )
}

export default Cursor
