import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import TileShimmer from '../shimmer/TileShimmer';
import { fetchPage } from '../../functions';

function JoinNowPopup() {


  const closePopup = (popup) => {
    popup.classList.toggle("d-none");
  }
  const [popupData,setPopupData] = useState({
    styles : {}
  });
  useEffect(() => {
    const getData =async ()=>{
      const data = await fetchPage('JoinNowPopup');
      setPopupData(data);
    }
    getData()
    
  }, [])
    
  return (
    <div className='joinNowPopup smallPopup' style={popupData.styles.body}>
      {
        (popupData) ? (
          <>
            <div className="yCenter justify-content-between">
              <h5 className="m-0 fw600">{popupData.title}</h5>
              <button className="btn-close" style={popupData.styles.crossBtn} onClick={(e) => closePopup(e.currentTarget.parentElement.parentElement)}></button>
            </div>
            <p className="m-0" dangerouslySetInnerHTML={{__html: popupData.text}} style={popupData.styles.text} />
            <div className="btns yCenter gap-2" style={popupData.styles.btns}>
              <Link target={popupData.primaryBtnTarget} to={popupData.primaryBtnLink} className='text-decoration-none'>
                <button className="btn" style={popupData.styles.primaryBtn}>{popupData.primaryBtnText}</button>
              </Link>
              <Link target={popupData.secondaryBtnTarget} to={popupData.secondaryBtnLink} className='text-decoration-none'>
                <button className="btn" style={popupData.styles.secondaryBtn}>{popupData.secondaryBtnText}</button>
              </Link>
            </div>
          </>
        ) : (
          <TileShimmer/>
        )
      }
    </div>
  )
}

export default JoinNowPopup
