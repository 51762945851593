import React, { useEffect, useState } from 'react';
import "./WhatsAppCta.css"
import { sendEvent, whatsAppCtaJs } from '../../../PopupsFunctions';
import { useLocation } from 'react-router-dom'
import PostShimmer from '../../shimmer/PostShimmer';
import { fetchPage } from '../../../functions';
// import { togglePopup } from '../../../functions';

function WhatsAppCta() {

  const { pathname } = useLocation();
  const [ctaData, setCtaData] = useState();
  useEffect(() => {
    whatsAppCtaJs();
    const getData = async () => {
      const data = await fetchPage('whatsappCta_chat_widget');
      setCtaData(data);
    }
    setTimeout(() => {
      document.querySelector(".blantershow-chat").click();
    }, 15000);
    getData()

  }, [pathname])


  return (

    <div id="whatsAppCta">
      {
        (ctaData) ? (
          <>
            {/* <div className="popupMask"></div> */}
            <div id="whatsapp-chat" className="hide" style={{ zIndex: "2147483647" }}>
              <div className="header-chat">
                <div className="head-home">
                  <div className="yCenter gap-2">
                    <div className="profilePic" style={{ width: "43px", marginBottom: "10px" }}>
                      <img src={ctaData.profilePic} alt="profile" className='w-100 h-100' />
                    </div>
                    <div className="d-flex flex-column h-100 justify-content-center gap-2 nameAndAbout">
                      <span className="whatsapp-name">{ctaData.comapanyTitle}</span>
                      <small className="whatsapp-status">{ctaData.aboutCompany}</small>
                    </div>
                  </div>
                </div>
                <div className="get-new hide">
                  <div id="get-label" />
                  <div id="get-nama" />
                </div>
              </div>
              <div className="home-chat"></div>
              <div className="start-chat">
                <div
                  pattern={ctaData.whatsappIcon}
                  className="WhatsappChat__Component-sc-1wqac52-0 whatsapp-chat-body"
                >
                  <div className="WhatsappChat__MessageContainer-sc-1wqac52-1 dAbFpq">
                    <div
                      style={{ opacity: 0 }}
                      className="WhatsappDots__Component-pks5bf-0 eJJEeC"
                    >
                      <div className="WhatsappDots__ComponentInner-pks5bf-1 hFENyl">
                        <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotOne-pks5bf-3 ixsrax" />
                        <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotTwo-pks5bf-4 dRvxoz" />
                        <div className="WhatsappDots__Dot-pks5bf-2 WhatsappDots__DotThree-pks5bf-5 kXBtNt" />
                      </div>
                    </div>
                    <div
                      style={{ opacity: 1 }}
                      className="WhatsappChat__Message-sc-1wqac52-4 kAZgZq"
                      dangerouslySetInnerHTML={{ __html: ctaData.msg }}
                    />
                  </div>
                </div>
                <div className="blanter-msg">
                  <input type="text" id="chat-input" className="removeTextareaArrows" placeholder="Write a response" maxLength={120} />
                  <div onClick={sendEvent} id="send-it">
                    <svg viewBox="0 0 448 448">
                      <path d="M.213 32L0 181.333 320 224 0 266.667.213 416 448 224z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div id="get-number" />
                <span className="close-chat text-decoration-none cursorPointer">
                  ×
                </span>
              </div>
              <div className="blantershow-chat  text-decoration-none cursorPointer" style={{ zIndex: "2147483647" }}>
              <svg width={20} viewBox="0 0 24 24">
                <defs />
                <path
                  fill="#eceff1"
                  d="M20.5 3.4A12.1 12.1 0 0012 0 12 12 0 001.7 17.8L0 24l6.3-1.7c2.8 1.5 5 1.4 5.8 1.5a12 12 0 008.4-20.3z"
                />
                <path
                  fill="#4caf50"
                  d="M12 21.8c-3.1 0-5.2-1.6-5.4-1.6l-3.7 1 1-3.7-.3-.4A9.9 9.9 0 012.1 12a10 10 0 0117-7 9.9 9.9 0 01-7 16.9z"
                />
                <path
                  fill="#fafafa"
                  d="M17.5 14.3c-.3 0-1.8-.8-2-.9-.7-.2-.5 0-1.7 1.3-.1.2-.3.2-.6.1s-1.3-.5-2.4-1.5a9 9 0 01-1.7-2c-.3-.6.4-.6 1-1.7l-.1-.5-1-2.2c-.2-.6-.4-.5-.6-.5-.6 0-1 0-1.4.3-1.6 1.8-1.2 3.6.2 5.6 2.7 3.5 4.2 4.2 6.8 5 .7.3 1.4.3 1.9.2.6 0 1.7-.7 2-1.4.3-.7.3-1.3.2-1.4-.1-.2-.3-.3-.6-.4z"
                />
              </svg>
              {ctaData.btnText}
            </div>
          </>
        ) : (
          <PostShimmer />
        )
      }
    </div>
  );
}

export default WhatsAppCta;
