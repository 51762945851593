import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import "./Thankyou.css"
import { fetchPage, togglePopup } from '../../functions';
import { Link } from 'react-router-dom'

function Thankyou() {

  const [popupData, setPopupData] = useState();

  useEffect(() => {
      const getData =async ()=>{
          const data = await fetchPage('dizzi_thanku_popup');
          setPopupData(data);
      }
      getData()
  }, [])


  const styles = {
    mainParent: {
      zIndex: "99", 
      // backdropFilter: "blur(3px)"
    },
    popupParent: {
      transform: "translate(-50%, -50%)",
      width: "450px", 
      maxWidth: "100%", 
      zIndex: "99999999999999999999",
      transition: "transform 600ms ease"
    },
    popup: {
      // border: "2px solid var(--red)",
      boxShadow: "0 20px 30px rgba(0,0,0,0.3)"
    },
    closeBtn: {
      fontSize: "1.5rem",
      color: "#fff",
    },
    socialIcon: {
      borderRadius: "50%",
      fontSize: "18px",
      width: "36px",
      height: "36px"
    },
    mainIcon: {
      color: "#198754",
      width: "70px",
      height: "70px",
      border: "3px solid #198754",
      borderRadius: "50%",
    }
  }

  if(popupData){
  return (
      <div id='thankuPopupCover' className='d-none'>
        <div id='thankyouPopupMask' className="container-fluid w-100 h-100 position-fixed top-0 end-0 bottom-0 start-0 bg-black bg-opacity-50" style={styles.mainParent}></div>
        <div id="thankyouPopup" className="mainPopup position-fixed top-50 start-50 p-3 slideAnimation" style={styles.popupParent}>
          <div className="p-3 p-sm-4 bg-white allCenter gap-3 gap-sm-4 position-relative" style={styles.popup}>
            <div className="position-absolute end-0 bottom-100 mb-2">
              <span className="closeBtn cursorPointer" style={styles.closeBtn} onClick={(e) => {togglePopup(document.getElementById("thankuPopupCover"), true)}}><FontAwesomeIcon icon={faXmark} /></span>
            </div>
            <span className="iconWrapper fs-1 waveAnimation allCenter" style={styles.mainIcon}><FontAwesomeIcon icon={faCheck} /></span>
            <h1 className="m-0 titleFont text-uppercase gradientText">{popupData.title}</h1>
            <p className="m-0 text-secondary">{popupData.subTitle}</p>
            <div className="socialLinks xCenter gap-3">
              <h5 className="m-0 text-uppercase">{popupData.socialTitle}</h5>
              <div className="icons yCenter justify-content-center gap-2">
                <Link target='_blank' to={popupData.socialIconsTargets.facebook} className="socialIcon allCenter icon" style={styles.socialIcon}><FontAwesomeIcon icon={faFacebook} /></Link>
                <Link target='_blank' to={popupData.socialIconsTargets.instagram} className="socialIcon allCenter icon" style={styles.socialIcon}><FontAwesomeIcon icon={faInstagram} /></Link>
                <Link target='_blank' to={popupData.socialIconsTargets.youtube} className="socialIcon allCenter icon" style={styles.socialIcon}><FontAwesomeIcon icon={faYoutube} /></Link>
                <Link target='_blank' to={popupData.socialIconsTargets.twitter} className="socialIcon allCenter icon" style={styles.socialIcon}><FontAwesomeIcon icon={faTwitter} /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } 
}

export default Thankyou