import React from 'react'
import "./Contact.css"
import ContactAll from '../contactAll/ContactAll'

function Contact({data}) {
  return (

    <div className='container contact mt-5'>
      <div className="row flex-row-reverse pt-5 row-gap-5">
        <div className="col-lg-6 mb-5 h-100" >
          <ContactAll data={data.formData} className="row h-100 center "/>
        </div>
        <div className="col-lg-6 d-flex align-items-end zoomeffect">
          <div className="imgHolder position-relative ">
            <img src={data.img} alt="girl-modal " className="w-100 h-100 objectFit imagesize" />
            <img src={data.social} alt='social-media' className='img-fluid socialImg position-absolute top-50 start-50 ' width={"25%"}/>
          </div>
        </div>
      </div>
    </div>
    // <div className='container contact mt-5'>
    //   <div className="row flex-row-reverse pt-5 row-gap-5">
    //     <div className="col-lg-5 pt-mg-5">
    //       <ContactAll data={data.formData} />
    //     </div>
    //     <div className="col-lg-7 d-flex align-items-end zoomeffect">
    //       <div className="imgHolder position-relative ">
    //         <img src={data.img} alt="girl-modal " className="w-100 h-100 objectFit imagesize" />
    //         <img src={data.social} alt='social-media' className='img-fluid socialImg position-absolute top-50 start-50 ' width={"25%"}/>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default Contact
