import React from 'react'
import TileShimmer from '../shimmer/TileShimmer'

function PostNotAvailable({data}) {

  if(data) {
    return (
      <div id='postNotAvailable' className='xCenter gap-3'>
        <h3 className='m-0 fw600'>{data.title}</h3>
        <p className='m-0 text-secondary'>{data.subTitle}</p>
        <p className="m-0">{data.text}</p>
      </div>
    )
  } else {
    return(
      <TileShimmer />
    )
  }
}

export default PostNotAvailable
