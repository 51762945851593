import React from 'react'
import "./FaqAboutService.css"
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TileShimmer from '../shimmer/TileShimmer'

function FaqAboutService({faqData}) {

    function toggleFaq(faqIndex){
        const faq = document.querySelector(`[data-faq=${faqIndex}]`)
        if(faq.classList.contains("activeFaq")) {
            faq.classList.remove("activeFaq");
            faq.querySelector(".answer").style.transitionDelay = "-700ms";
            faq.querySelector(".plus").classList.remove("d-none");
            faq.querySelector(".minus").classList.add("d-none");
        } else {
            faq.querySelector(".answer").style.transitionDelay = "0ms";
            faq.classList.add("activeFaq");
            faq.querySelector(".plus").classList.add("d-none");
            faq.querySelector(".minus").classList.remove("d-none");
        }
    }

    if(faqData){
        return (
          <div className='faqAboutServiceContainer'>
              <h3 className="fw700 title">{faqData.title}</h3>
              <div className="content row mt-4 row-gap-4">
                  <div className="col-md-5 transition">
                      <div className="imgWrapper thumbnail transition hovereffect"><img src={faqData.thumbnail} alt="thumbnail" className='w-100 h-100 transition' /></div>
                  </div>
                  <div className="col-md-7">
                      <div className="faqs xCenter gap-2">
                          {faqData.faqs.map((faq, index) => {
                              return(
                                <div data-faq={`faq${index}`} key={"faq"+index} className="faq w-100" onClick={()=>{toggleFaq(`faq${index}`)}}>
                                    <div className="question faqBg position-relative yCenter justify-content-between gap-3">
                                        <h5 className="fw600 m-0 w-100 textHoverEffect">{faq.ques}</h5>
                                        <div className="vectorWrapper caret plus"><FontAwesomeIcon icon={faPlus} /></div>
                                        <div className="vectorWrapper caret minus d-none"><FontAwesomeIcon icon={faMinus} /></div>
                                    </div>
                                    <div className="answer px-4 my-2">
                                        <p className="text-secondary m-0 my-2">{faq.ans}</p>
                                    </div>
                                </div>
                              )
                          })}
                      </div>
                  </div>
              </div>
          </div>
        )
    }else{
        return <TileShimmer/>
    }

}

export default FaqAboutService
