import React from 'react'
import "./Companies.css"
import TileShimmer from '../shimmer/TileShimmer';

function Companies({data}) {

    //     function slider() {
    //         let para1 = document.getElementById("companies");

    // console.log(para1);

    //         animate(para1); 

    //         function animate(element) {
    //             let elementWidth = element.offsetWidth;
    //             let parentWidth = element.parentElement.offsetWidth;
    //             let flag = 0;

    //             setInterval(() => {
    //                 element.style.marginLeft = --flag + "px";

    //                 if (elementWidth == -flag) {
    //                     flag = parentWidth;
    //                 }
    //             }, 10);
    //         }
    //     }


    if(data) {
        return (
            <div className='container-fluid p-3 py-4 overflow-hidden yCenter' style={{backgroundColor: "#686f73"}}>
                <div className="companies yCenter" style={{gap: "5rem", paddingRight: "5rem"}}>
                    {/* <Splide options={{
                        perPage: 6,
                        perMove: 1,
                        // focus: "center",
                        // gap: 1,
                        lazyLoad: true,
                        keyboard: true,
                        direction: "ltr",
                        speed: 500,
                        type: "loop",
                        drag: true,
                        snap: true,
                        autoplay: true,
                        interval: 1500,
                        arrows: false,
                        pagination: false,
                    }} aria-label='Achievments'> */}
                        {data.map((company,index) => {
                            return (
                                // (company) ?
                                // <SplideSlide>
                                // <>
                                    <div key={"company"+index} className="companyImg" style={{width: "140px"}}>
                                        {(company != null) ? <img src={company} alt="company" className='w-100 h-100' /> : <TileShimmer key={"company"+index}/>}
                                    </div>
                                

                            )
                        })}
                    {/* </Splide> */}
                </div>
                <div className="companies yCenter" style={{gap: "5rem", paddingRight: "5rem"}}>
                    {/* <Splide options={{
                        perPage: 6,
                        perMove: 1,
                        // focus: "center",
                        // gap: 1,
                        lazyLoad: true,
                        keyboard: true,
                        direction: "ltr",
                        speed: 500,
                        type: "loop",
                        drag: true,
                        snap: true,
                        autoplay: true,
                        interval: 1500,
                        arrows: false,
                        pagination: false,
                    }} aria-label='Achievments'> */}
                        {data.map((company,index) => {
                            return (
                                // (company) ?
                                // <SplideSlide>
                                // <>
                                    <div key={"company"+index} className="companyImg" style={{width: "140px"}}>
                                        {(company != null) ? <img src={company} alt="company" className='w-100 h-100' /> : <TileShimmer key={"company"+index}/>}
                                    </div>
                                

                            )
                        })}
                    {/* </Splide> */}
                </div>
                <div className="companies yCenter" style={{gap: "5rem", paddingRight: "5rem"}}>
                    {/* <Splide options={{
                        perPage: 6,
                        perMove: 1,
                        // focus: "center",
                        // gap: 1,
                        lazyLoad: true,
                        keyboard: true,
                        direction: "ltr",
                        speed: 500,
                        type: "loop",
                        drag: true,
                        snap: true,
                        autoplay: true,
                        interval: 1500,
                        arrows: false,
                        pagination: false,
                    }} aria-label='Achievments'> */}
                        {data.map((company,index) => {
                            return (
                                // (company) ?
                                // <SplideSlide>
                                // <>
                                    <div key={"company"+index} className="companyImg" style={{width: "140px"}}>
                                        {(company != null) ? <img src={company} alt="company" className='w-100 h-100' /> : <TileShimmer key={"company"+index}/>}
                                    </div>
                                

                            )
                        })}
                    {/* </Splide> */}
                </div>
            </div>
        )
    } else {
        return(<TileShimmer />)
    }
}
export default Companies