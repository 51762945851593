import React, { useEffect, useState } from 'react'
import { fetchPosts } from '../../functions';
import TileShimmer from '../shimmer/TileShimmer';
import "./Recent.css";
import { Link } from 'react-router-dom';

function Recent() {

    const data = {
        heading: "Recent Articles",
        articles: [
            {
                thumbnail: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/blog/recent-blog1.jpg",
                data: "09 January 2023",
                title: "Bootcamp; Change To Impressive Marketing Policy.",
            },
            {
                thumbnail: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/blog/recent-blog2.jpg",
                data: "03 March 2023",
                title: "Become A Expert Any Business Field.",
            },
            {
                thumbnail: "https://demo-egenslab.b-cdn.net/html/techgen/preview/assets/images/blog/recent-blog3.jpg",
                data: "21 April 2023",
                title: "Discussion Always Be Fast To Change Marketing Strategy.",
            },
        ]
    }
    const tempPosts = [null, null, null];

    const [posts,setPosts] = useState(tempPosts);
    const [postsCount] = useState(3);


    useEffect(() => {
        const getData =async ()=>{
            const posts = await fetchPosts(postsCount);
            if(posts){
                setPosts(posts);
            }
        }
        getData();
    }, [postsCount])

    return (
        <div className='recentPosts- somePosts xCenter gap-3'>
            <h2 className='m-0 fs-4 fw-bold'>{data.heading}:</h2>
            {posts.map((post,index) => {
                if(post){
                    const postContent = JSON.parse(post['content']);
                    return (
                        <Link key={"recentPost"+index} className='nav-link' to={"/"+post.slug}>
                            <div className="article d-flex justify-content-start align-items-start gap-2">
                                <div className="thumbnail scaleOnHover overflow-hidden mt-0">
                                    <img src={postContent.thumbnail} alt="thumbnail" className='w-100 h-100 objectFit'/>
                                </div>
                                <div className="content xCenter text-start justify-content-start">
                                    <p className='m-0 gradientText'>{postContent.data}</p>
                                    <h2 className='h5 fw-semibold'>{postContent.title}</h2>
                                </div>
                            </div>
                        </Link>
                    )
                }else{
                    return <TileShimmer key={"recentPost"+index} />
                }
            })}
        </div>
    )
}

export default Recent
