import React, { useEffect } from 'react'

function PostImg({images}) {
    useEffect(() => {
      var elem = document.querySelectorAll(".imgItem");
      
      if(images.length === 3) {
        elem.forEach((img) => {
          img.classList.remove("col")
          img.classList.add("col-6");
        })
        elem[0].classList.replace("col-6","col-12");
      } else if(images.length % 2 === 0) {
        elem.forEach((img) => {
          img.classList.remove("col","col-12");
          img.classList.add("col-6");
        })
      } 
      else {
        elem.forEach((img) => {
          img.classList.remove("col")
          img.classList.add("col-6");
        })
        elem[0].classList.replace("col-6","col-12");
      }
      
    });
  return (

    <div className='postImgs py-3 py-md-4 row row-gap-3'>
      {images.map((img,index) => {
        return (
          <div key={"postImages"+index} className="px-2 imgItem col">
            <img src={img} alt="post" height={"100%"} width={"100%"}/>
          </div>
        )

      })}
    </div>
  )

}
export default PostImg
