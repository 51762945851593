import { faSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TileShimmer from "../shimmer/TileShimmer"
import { Link } from 'react-router-dom'

function Services({data}) {
  return (
    <div className="container py-5">
      <div className="row ">
        <div className="col-12 allCenter">
          <h5 className="gradientText yCenter gap-2">
            <FontAwesomeIcon icon={faSquare} className="square" />
            {data.subTitle}
            <FontAwesomeIcon icon={faSquare} className="square" />
          </h5>
          <h2 className="h1 titleFont" dangerouslySetInnerHTML={{ __html: data.title }} />
        </div>
      </div>
      <div className="row mt-3">
        {data.serviceCards.map((serviceCard, index) => {
          return (
            (serviceCard) ?
            <div key={"serviceCard"+index} className="col-sm-6 col-md-4 mb-5 mb-sm-3">
              <div className="title yCenter gap-2 transition">
                <img src="https://admin.dizzibooster.com/public/images/qarpmfkdglo6505da3ad1e6c.png" alt="icon" style={{height:"50px"}} />
                <img src="img/line.svg" alt="" />
                <p className="h4 m-0 fw-semibold">{serviceCard.title}</p>
              </div>
              <div className="content ps-5">
                <p className="mb-2 text-secondary">{serviceCard.text}</p>
                <Link to={serviceCard.link} className="blackLink yCenter m-0 gap-2 fw-semibold">
                  <img src="https://dizzibooster.com/img/arrow.svg" alt="icon" />
                  {serviceCard.linkText}
                </Link>
              </div>
            </div>
            :
            <TileShimmer key={"serviceCard"+index} />
          )
        })}
      </div>
    </div>
  )
}

export default Services
