import React, { useEffect, useState } from 'react'
import HeroBasic from '../components/heroBasic/HeroBasic'
import Category from '../components/category/Category'
import Recent from '../components/recent/Recent'
import ContactForm from '../components/contactForm/ContactForm'
import SubscribeBar from '../components/subscribBar/SubscribeBar'
import ServicesShort from '../components/serviceShort/ServicesShort'
import Posts from '../components/posts/Posts'
import { fetchPage } from '../functions'
import HeadTag from '../components/headTag/HeadTag'

function Blog() {

  const tempData = {
    hero : null,
    services : {
      heading: "Services",
      services: [null, null]
    }
  }

  const [blogData,setBlogData] = useState(tempData);
  const [head,setHead] = useState(null);
  useEffect(() => {
    const getData =async ()=>{
      const data = await fetchPage('dizzi_blog');
      setBlogData(data);
      const head = await fetchPage('dizzi_blog_head');
      setHead(head);
    }
    getData()
    
  }, [])
  

  return (
    <div className='container-fluid gx-0'>
      <HeadTag data={head}/>
      <HeroBasic data={blogData.hero}/>
      <div className="container">
        <div className="row blog-shortComponents py-2 py-md-4">
          <div className="col-lg-8 pe-2 pe-md-5 py-2 py-md-4">
            <Posts/>
          </div>
          <div className="col-lg-4 py-2 py-md-4">
            <div className="row row-gap-5">
              <div className="col-md-6 col-lg-12">
                <div className='p-3 p-md-4 mt-5 border recent blogpageRecent'><Recent /></div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className='serviceShort'><ServicesShort data={blogData.services} /></div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className='p-3 p-md-4 category border'><Category /></div>
              </div>
              <div className="col-md-6 col-lg-12">
                <div className='p-3 p-md-4 contact' style={{ backgroundColor: "#fff9ee" }}><ContactForm /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pt-3'><SubscribeBar /></div>
    </div>
  )
}

export default Blog
