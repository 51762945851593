import { faCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "./HeroBasic.css"
import TileShimmer from '../shimmer/TileShimmer'

function HeroBasic({data}) {

    return (
        <div className={`col heroBasic allCenter position-relative content-element ${data ? 'loaded' : ''} `}>
            {(data!=null) ? 
            <>
                <div className="mask"></div>
                <div className="blackShade"><img className='w-100 h-100' src={data.img} alt="shade" style={{objectFit: "cover"}} /></div>
                <div className="textSection allCenter text-white gap-3">
                    <h2 className='titleFont bigHeading'>{data.title}</h2>
                    <div className="path yCenter justify-content-center gap-2">
                        <p className='m-0'>{data.main}</p>
                        <span style={{width: "20px"}}><img src="https://admin.dizzibooster.com/public/images/vector.svg" alt="vector" className='w-100 h-100' /></span>
                        <p className='m-0 gradientText'>{data.currentPg}</p>
                    </div>
                    <div className="allCenter position-relative">
                        <FontAwesomeIcon icon={faCircleDown} className='icon'/>
                        {/* <FontAwesomeIcon icon={faArrowDown} className='icon position-absolute top-50' /> */}
                    </div>
                </div>
            </>
            :
            <TileShimmer/>
        }

        </div>
    )
}

export default HeroBasic
